/* Ignore prepended by codegen.yml */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query'
import { useFetchData } from './Fetcher'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  DateTime: { input: any; output: any }
  JSONString: { input: any; output: any }
  UUID: { input: any; output: any }
}

/** An enumeration. */
export enum AuditLogAction {
  /** create */
  Create = 'CREATE',
  /** delete */
  Delete = 'DELETE',
  /** update */
  Update = 'UPDATE',
}

/** An enumeration. */
export enum AuditLogResourceType {
  /** file system */
  FileSystem = 'FILE_SYSTEM',
  /** session */
  Session = 'SESSION',
  /** sshkey */
  SshKey = 'SSH_KEY',
  /** team */
  Team = 'TEAM',
  /** user */
  User = 'USER',
  /** vm */
  Vm = 'VM',
  /** workload definition */
  WorkloadDefinition = 'WORKLOAD_DEFINITION',
  /** workload run */
  WorkloadRun = 'WORKLOAD_RUN',
}

export type AuditLogType = Node & {
  __typename?: 'AuditLogType'
  action: AuditLogAction
  /** Additional context */
  context?: Maybe<Scalars['String']['output']>
  /** The ID of the object */
  id: Scalars['ID']['output']
  pk?: Maybe<Scalars['String']['output']>
  resourceId?: Maybe<Scalars['UUID']['output']>
  resourceName?: Maybe<Scalars['String']['output']>
  resourceType: AuditLogResourceType
  teamName?: Maybe<Scalars['String']['output']>
  timestamp: Scalars['DateTime']['output']
  username: Scalars['String']['output']
}

export type AuditLogTypeConnection = {
  __typename?: 'AuditLogTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<AuditLogTypeEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `AuditLogType` and its cursor. */
export type AuditLogTypeEdge = {
  __typename?: 'AuditLogTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node: AuditLogType
}

export type ClusterConnection = {
  __typename?: 'ClusterConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ClusterEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `Cluster` and its cursor. */
export type ClusterEdge = {
  __typename?: 'ClusterEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<ClusterType>
}

export type ClusterType = Node & {
  __typename?: 'ClusterType'
  createdAt: Scalars['DateTime']['output']
  /** The ID of the object */
  id: Scalars['ID']['output']
  pk?: Maybe<Scalars['String']['output']>
  slug: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ClusterTypeConnection = {
  __typename?: 'ClusterTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<ClusterTypeEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `ClusterType` and its cursor. */
export type ClusterTypeEdge = {
  __typename?: 'ClusterTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node: ClusterType
}

/** An enumeration. */
export enum ContainerRuntimeRequirementsAvailability {
  /** AVAILABLE */
  Available = 'AVAILABLE',
  /** DISABLED */
  Disabled = 'DISABLED',
  /** PREVIEW */
  Preview = 'PREVIEW',
}

export type ContainerRuntimeRequirementsType = Node & {
  __typename?: 'ContainerRuntimeRequirementsType'
  availability: ContainerRuntimeRequirementsAvailability
  /** The ID of the object */
  id: Scalars['ID']['output']
  /** Human-readable description of the tags */
  label: Scalars['String']['output']
  /** Resource for the runtime, ex. tenstorrent.com/n300 */
  resourceName: Scalars['String']['output']
}

export type ContainerRuntimeRequirementsTypeConnection = {
  __typename?: 'ContainerRuntimeRequirementsTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<ContainerRuntimeRequirementsTypeEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `ContainerRuntimeRequirementsType` and its cursor. */
export type ContainerRuntimeRequirementsTypeEdge = {
  __typename?: 'ContainerRuntimeRequirementsTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node: ContainerRuntimeRequirementsType
}

export type FileSystemConnection = {
  __typename?: 'FileSystemConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FileSystemEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

export type FileSystemCreateInput = {
  capacity: Scalars['String']['input']
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  teamId: Scalars['String']['input']
}

export type FileSystemCreatePayload = {
  __typename?: 'FileSystemCreatePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  fileSystem: FileSystemType
}

export type FileSystemDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type FileSystemDeletePayload = {
  __typename?: 'FileSystemDeletePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  fileSystem: FileSystemType
}

/** A Relay edge containing a `FileSystem` and its cursor. */
export type FileSystemEdge = {
  __typename?: 'FileSystemEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<FileSystemType>
}

/** An enumeration. */
export enum FileSystemStatus {
  /** CREATING */
  Creating = 'CREATING',
  /** DELETED */
  Deleted = 'DELETED',
  /** ERROR */
  Error = 'ERROR',
  /** READY */
  Ready = 'READY',
  /** REQUEST_CREATE */
  RequestCreate = 'REQUEST_CREATE',
  /** REQUEST_DELETE */
  RequestDelete = 'REQUEST_DELETE',
}

export type FileSystemType = Node & {
  __typename?: 'FileSystemType'
  capacity: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  createdBy: UserType
  errorMessage?: Maybe<Scalars['String']['output']>
  /** The ID of the object */
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  pk?: Maybe<Scalars['String']['output']>
  slug: Scalars['String']['output']
  status: FileSystemStatus
  team: TeamType
  updatedAt: Scalars['DateTime']['output']
}

export type FileSystemTypeConnection = {
  __typename?: 'FileSystemTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<FileSystemTypeEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `FileSystemType` and its cursor. */
export type FileSystemTypeEdge = {
  __typename?: 'FileSystemTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node: FileSystemType
}

export type GenerateKubeconfigForClusterInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  clusterId?: InputMaybe<Scalars['String']['input']>
}

export type GenerateKubeconfigForClusterPayload = {
  __typename?: 'GenerateKubeconfigForClusterPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  kubeconfig: Scalars['String']['output']
}

export type GroupType = Node & {
  __typename?: 'GroupType'
  /** The ID of the object */
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  pk?: Maybe<Scalars['String']['output']>
  userSet: UserTypeConnection
}

export type GroupTypeUserSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  email_Icontains?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  firstName_Icontains?: InputMaybe<Scalars['String']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  lastName_Icontains?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  username_Icontains?: InputMaybe<Scalars['String']['input']>
}

export type HardwareConnection = {
  __typename?: 'HardwareConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HardwareEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `Hardware` and its cursor. */
export type HardwareEdge = {
  __typename?: 'HardwareEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<HardwareType>
}

export type HardwareModelType = Node & {
  __typename?: 'HardwareModelType'
  createdAt: Scalars['DateTime']['output']
  /** The ID of the object */
  id: Scalars['ID']['output']
  manufacturer?: Maybe<ManufacturerType>
  modelIdentifier: Scalars['String']['output']
  modelName: Scalars['String']['output']
  pk?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type HardwareType = Node & {
  __typename?: 'HardwareType'
  availableForReservation?: Maybe<Scalars['Boolean']['output']>
  canBeReserved: Scalars['Boolean']['output']
  hardwareModel?: Maybe<HardwareModelType>
  /** The ID of the object */
  id: Scalars['ID']['output']
  isHealthy: Scalars['Boolean']['output']
  isQuarantined: Scalars['Boolean']['output']
  isReserved: Scalars['Boolean']['output']
  parentId?: Maybe<Scalars['String']['output']>
  pk?: Maybe<Scalars['String']['output']>
  slug: Scalars['String']['output']
  teamName?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type HardwareTypeConnection = {
  __typename?: 'HardwareTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<HardwareTypeEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `HardwareType` and its cursor. */
export type HardwareTypeEdge = {
  __typename?: 'HardwareTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node: HardwareType
}

export type IngressRouteAssignInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  workloadRunId?: InputMaybe<Scalars['ID']['input']>
}

export type IngressRouteAssignPayload = {
  __typename?: 'IngressRouteAssignPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok: Scalars['Boolean']['output']
}

export type IngressRouteConnection = {
  __typename?: 'IngressRouteConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IngressRouteEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

export type IngressRouteCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  subdomain: Scalars['String']['input']
  workloadDefinitionId: Scalars['ID']['input']
}

export type IngressRouteCreatePayload = {
  __typename?: 'IngressRouteCreatePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok: Scalars['Boolean']['output']
}

export type IngressRouteDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  workloadDefinitionId: Scalars['ID']['input']
}

export type IngressRouteDeletePayload = {
  __typename?: 'IngressRouteDeletePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok: Scalars['Boolean']['output']
}

/** A Relay edge containing a `IngressRoute` and its cursor. */
export type IngressRouteEdge = {
  __typename?: 'IngressRouteEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<IngressRouteType>
}

export type IngressRouteType = Node & {
  __typename?: 'IngressRouteType'
  /** The ID of the object */
  id: Scalars['ID']['output']
  pk?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
  workloadRun?: Maybe<WorkloadRunType>
}

export type IngressRouteTypeConnection = {
  __typename?: 'IngressRouteTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<IngressRouteTypeEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `IngressRouteType` and its cursor. */
export type IngressRouteTypeEdge = {
  __typename?: 'IngressRouteTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node: IngressRouteType
}

export type KubeconfigType = {
  __typename?: 'KubeconfigType'
  kubeconfig: Scalars['String']['output']
}

export type LoginInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  password: Scalars['String']['input']
  username: Scalars['String']['input']
}

export type LoginPayload = {
  __typename?: 'LoginPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  token: TokenType
}

export type LogoutInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  confirm: Scalars['Boolean']['input']
}

export type LogoutPayload = {
  __typename?: 'LogoutPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok: Scalars['Boolean']['output']
}

export type ManufacturerType = Node & {
  __typename?: 'ManufacturerType'
  /** The ID of the object */
  id: Scalars['ID']['output']
  pk?: Maybe<Scalars['String']['output']>
  slug: Scalars['String']['output']
}

export type Mutation = {
  __typename?: 'Mutation'
  fileSystemCreate: FileSystemCreatePayload
  fileSystemDelete: FileSystemDeletePayload
  /** @deprecated Superseded by `kubeconfig` query */
  generateKubeconfigForCluster: GenerateKubeconfigForClusterPayload
  ingressRouteAssign: IngressRouteAssignPayload
  ingressRouteCreate: IngressRouteCreatePayload
  ingressRouteDelete: IngressRouteDeletePayload
  login: LoginPayload
  logout: LogoutPayload
  passwordChange: PasswordChangePayload
  serverCreate: ServerCreatePayload
  serverDelete: ServerDeletePayload
  serverHardReboot: ServerHardRebootPayload
  serverShutoff: ServerShutoffPayload
  serverSoftReboot: ServerSoftRebootPayload
  serverStart: ServerStartPayload
  signup: SignupPayload
  sshKeyCreate: SshKeyCreatePayload
  sshKeyDelete: SshKeyDeletePayload
  teamAddUser: TeamAddUserPayload
  teamChangeUserGroup: TeamChangeUserGroupPayload
  teamCreate: TeamCreatePayload
  teamRemoveUser: TeamRemoveUserPayload
  userUpdate: UserUpdatePayload
  workloadDefinitionCreate: WorkloadDefinitionCreatePayload
  workloadDefinitionDelete: WorkloadDefinitionDeletePayload
  workloadDefinitionUnarchive: WorkloadDefinitionUnarchivePayload
  workloadDefinitionUpdate: WorkloadDefinitionUpdatePayload
  workloadRunCommentUpdate: WorkloadRunCommentUpdatePayload
  workloadRunCreate: WorkloadRunCreatePayload
  workloadRunDelete: WorkloadRunDeletePayload
  workloadRunRestart: WorkloadRunRestartPayload
}

export type MutationFileSystemCreateArgs = {
  input: FileSystemCreateInput
}

export type MutationFileSystemDeleteArgs = {
  input: FileSystemDeleteInput
}

export type MutationGenerateKubeconfigForClusterArgs = {
  input: GenerateKubeconfigForClusterInput
}

export type MutationIngressRouteAssignArgs = {
  input: IngressRouteAssignInput
}

export type MutationIngressRouteCreateArgs = {
  input: IngressRouteCreateInput
}

export type MutationIngressRouteDeleteArgs = {
  input: IngressRouteDeleteInput
}

export type MutationLoginArgs = {
  input: LoginInput
}

export type MutationLogoutArgs = {
  input: LogoutInput
}

export type MutationPasswordChangeArgs = {
  input: PasswordChangeInput
}

export type MutationServerCreateArgs = {
  input: ServerCreateInput
}

export type MutationServerDeleteArgs = {
  input: ServerDeleteInput
}

export type MutationServerHardRebootArgs = {
  input: ServerHardRebootInput
}

export type MutationServerShutoffArgs = {
  input: ServerShutoffInput
}

export type MutationServerSoftRebootArgs = {
  input: ServerSoftRebootInput
}

export type MutationServerStartArgs = {
  input: ServerStartInput
}

export type MutationSignupArgs = {
  input: SignupInput
}

export type MutationSshKeyCreateArgs = {
  input: SshKeyCreateInput
}

export type MutationSshKeyDeleteArgs = {
  input: SshKeyDeleteInput
}

export type MutationTeamAddUserArgs = {
  input: TeamAddUserInput
}

export type MutationTeamChangeUserGroupArgs = {
  input: TeamChangeUserGroupInput
}

export type MutationTeamCreateArgs = {
  input: TeamCreateInput
}

export type MutationTeamRemoveUserArgs = {
  input: TeamRemoveUserInput
}

export type MutationUserUpdateArgs = {
  input: UserUpdateInput
}

export type MutationWorkloadDefinitionCreateArgs = {
  input: WorkloadDefinitionCreateInput
}

export type MutationWorkloadDefinitionDeleteArgs = {
  input: WorkloadDefinitionDeleteInput
}

export type MutationWorkloadDefinitionUnarchiveArgs = {
  input: WorkloadDefinitionUnarchiveInput
}

export type MutationWorkloadDefinitionUpdateArgs = {
  input: WorkloadDefinitionUpdateInput
}

export type MutationWorkloadRunCommentUpdateArgs = {
  input: WorkloadRunCommentUpdateInput
}

export type MutationWorkloadRunCreateArgs = {
  input: WorkloadRunCreateInput
}

export type MutationWorkloadRunDeleteArgs = {
  input: WorkloadRunDeleteInput
}

export type MutationWorkloadRunRestartArgs = {
  input: WorkloadRunRestartInput
}

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID']['output']
}

/** An enumeration. */
export enum OpenStackFlavorAvailability {
  /** AVAILABLE */
  Available = 'AVAILABLE',
  /** DELETED */
  Deleted = 'DELETED',
  /** DISABLED */
  Disabled = 'DISABLED',
  /** PREVIEW */
  Preview = 'PREVIEW',
}

export type OpenStackFlavorType = Node & {
  __typename?: 'OpenStackFlavorType'
  availability?: Maybe<OpenStackFlavorAvailability>
  createdAt: Scalars['DateTime']['output']
  /** The ID of the object */
  id: Scalars['ID']['output']
  /** Flavor name shown to user */
  name: Scalars['String']['output']
  pk?: Maybe<Scalars['String']['output']>
  slug: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  /** Virtual Network Interface Card (VNIC) requirement for the server (SR-IOV is NUMA-bound) */
  vnicType: OpenStackFlavorVnicType
}

export type OpenStackFlavorTypeConnection = {
  __typename?: 'OpenStackFlavorTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<OpenStackFlavorTypeEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `OpenStackFlavorType` and its cursor. */
export type OpenStackFlavorTypeEdge = {
  __typename?: 'OpenStackFlavorTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node: OpenStackFlavorType
}

/** An enumeration. */
export enum OpenStackFlavorVnicType {
  /** Direct (SR-IOV) */
  Direct = 'DIRECT',
  /** Normal */
  Normal = 'NORMAL',
}

/** An enumeration. */
export enum OpenStackImageAvailability {
  /** AVAILABLE */
  Available = 'AVAILABLE',
  /** DELETED */
  Deleted = 'DELETED',
  /** DISABLED */
  Disabled = 'DISABLED',
  /** PREVIEW */
  Preview = 'PREVIEW',
}

export type OpenStackImageType = Node & {
  __typename?: 'OpenStackImageType'
  availability?: Maybe<OpenStackImageAvailability>
  createdAt: Scalars['DateTime']['output']
  defaultSshUser: Scalars['String']['output']
  /** The ID of the object */
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  pk?: Maybe<Scalars['String']['output']>
  slug: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type OpenStackImageTypeConnection = {
  __typename?: 'OpenStackImageTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<OpenStackImageTypeEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `OpenStackImageType` and its cursor. */
export type OpenStackImageTypeEdge = {
  __typename?: 'OpenStackImageTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node: OpenStackImageType
}

/** An enumeration. */
export enum OpenStackServerStatus {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** BUILD */
  Build = 'BUILD',
  /** CREATING */
  Creating = 'CREATING',
  /** DELETED */
  Deleted = 'DELETED',
  /** ERROR */
  Error = 'ERROR',
  /** HARD_REBOOT */
  HardReboot = 'HARD_REBOOT',
  /** INITIAL */
  Initial = 'INITIAL',
  /** INTERNAL_ERROR */
  InternalError = 'INTERNAL_ERROR',
  /** PAUSED */
  Paused = 'PAUSED',
  /** REBOOT */
  Reboot = 'REBOOT',
  /** REQUEST_CREATE */
  RequestCreate = 'REQUEST_CREATE',
  /** REQUEST_DELETE */
  RequestDelete = 'REQUEST_DELETE',
  /** REQUEST_HARD_REBOOT */
  RequestHardReboot = 'REQUEST_HARD_REBOOT',
  /** REQUEST_SHUTOFF */
  RequestShutoff = 'REQUEST_SHUTOFF',
  /** REQUEST_SOFT_REBOOT */
  RequestSoftReboot = 'REQUEST_SOFT_REBOOT',
  /** REQUEST_START */
  RequestStart = 'REQUEST_START',
  /** SHUTOFF */
  Shutoff = 'SHUTOFF',
  /** SUSPENDED */
  Suspended = 'SUSPENDED',
}

export type OpenStackServerType = Node & {
  __typename?: 'OpenStackServerType'
  /** Compute Host system of this OpenStack server instance */
  computeHost?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  createdBy: UserType
  errorMessage?: Maybe<Scalars['String']['output']>
  /** The ID of the object */
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  openstackFlavor: OpenStackFlavorType
  openstackFloatingIp?: Maybe<Scalars['String']['output']>
  openstackImage: OpenStackImageType
  pk?: Maybe<Scalars['String']['output']>
  slug: Scalars['String']['output']
  status: OpenStackServerStatus
  teamId: Scalars['String']['output']
  teamName: Scalars['String']['output']
  teamSlug: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type OpenStackServerTypeConnection = {
  __typename?: 'OpenStackServerTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<OpenStackServerTypeEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `OpenStackServerType` and its cursor. */
export type OpenStackServerTypeEdge = {
  __typename?: 'OpenStackServerTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node: OpenStackServerType
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>
}

export type PasswordChangeInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  newPassword: Scalars['String']['input']
  oldPassword: Scalars['String']['input']
}

export type PasswordChangePayload = {
  __typename?: 'PasswordChangePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok: Scalars['Boolean']['output']
}

export type Query = {
  __typename?: 'Query'
  auditlogs: AuditLogTypeConnection
  clusters: ClusterTypeConnection
  containerRuntimeRequirements: ContainerRuntimeRequirementsTypeConnection
  fileSystems: FileSystemTypeConnection
  flavors: OpenStackFlavorTypeConnection
  hardwares: HardwareTypeConnection
  ingressRoutes: IngressRouteTypeConnection
  kubeconfig: KubeconfigType
  openStackImages: OpenStackImageTypeConnection
  reservations: ReservationTypeConnection
  servers: OpenStackServerTypeConnection
  sshKeys: SshKeyTypeConnection
  team: TeamType
  teams: TeamTypeConnection
  users: UserTypeConnection
  whoami: WhoamiType
  workloadDefinitions: WorkloadDefinitionTypeConnection
  workloadRuns: WorkloadRunTypeConnection
  workloadRuntimeCapacity: WorkloadRuntimeCapacityType
}

export type QueryAuditlogsArgs = {
  action?: InputMaybe<Scalars['String']['input']>
  action_In?: InputMaybe<Array<InputMaybe<AuditLogAction>>>
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  resourceName_Icontains?: InputMaybe<Scalars['String']['input']>
  resourceType?: InputMaybe<Scalars['String']['input']>
  resourceType_In?: InputMaybe<Array<InputMaybe<AuditLogResourceType>>>
  teamId?: InputMaybe<Scalars['ID']['input']>
  team_Name_Icontains?: InputMaybe<Scalars['String']['input']>
  token_User_Username_Icontains?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryClustersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  clusterSlug?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryContainerRuntimeRequirementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
}

export type QueryFileSystemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdBy_Id?: InputMaybe<Scalars['ID']['input']>
  createdBy_Username?: InputMaybe<Scalars['String']['input']>
  createdBy_Username_Icontains?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  name_Icontains?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  status_In?: InputMaybe<Array<InputMaybe<FileSystemStatus>>>
  teamId?: InputMaybe<Scalars['ID']['input']>
  team_Name?: InputMaybe<Scalars['String']['input']>
}

export type QueryFlavorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  availability?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
}

export type QueryHardwaresArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_Contains?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_Gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_Lt?: InputMaybe<Scalars['DateTime']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  hardwareModel_ModelName_Icontains?: InputMaybe<Scalars['String']['input']>
  isHealthy?: InputMaybe<Scalars['Boolean']['input']>
  isQuarantined?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  slug_Icontains?: InputMaybe<Scalars['String']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
  team_Name_Icontains?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt_Contains?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt_Gt?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt_Lt?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryIngressRoutesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  workloadDefinitionId: Scalars['ID']['input']
}

export type QueryOpenStackImagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  availability?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
}

export type QueryReservationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_Contains?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_Gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_Lt?: InputMaybe<Scalars['DateTime']['input']>
  createdBy_Username_Icontains?: InputMaybe<Scalars['String']['input']>
  endedAt?: InputMaybe<Scalars['DateTime']['input']>
  endedAt_Contains?: InputMaybe<Scalars['DateTime']['input']>
  endedAt_Gt?: InputMaybe<Scalars['DateTime']['input']>
  endedAt_Lt?: InputMaybe<Scalars['DateTime']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  requestedHardwareModel_ModelIdentifier_Icontains?: InputMaybe<
    Scalars['String']['input']
  >
  startedAt?: InputMaybe<Scalars['DateTime']['input']>
  startedAt_Contains?: InputMaybe<Scalars['DateTime']['input']>
  startedAt_Gt?: InputMaybe<Scalars['DateTime']['input']>
  startedAt_Lt?: InputMaybe<Scalars['DateTime']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
  team_Name_Icontains?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt_Contains?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt_Gt?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt_Lt?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryServersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  computeHost_Icontains?: InputMaybe<Scalars['String']['input']>
  createdBy_Id?: InputMaybe<Scalars['ID']['input']>
  createdBy_Username?: InputMaybe<Scalars['String']['input']>
  createdBy_Username_Icontains?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  name_Icontains?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  openstackFlavor_Name?: InputMaybe<Scalars['String']['input']>
  openstackFloatingIp_Icontains?: InputMaybe<Scalars['String']['input']>
  openstackImage_Name?: InputMaybe<Scalars['String']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  status_In?: InputMaybe<Array<InputMaybe<OpenStackServerStatus>>>
  teamId?: InputMaybe<Scalars['ID']['input']>
  team_Name?: InputMaybe<Scalars['String']['input']>
}

export type QuerySshKeysArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryTeamArgs = {
  id: Scalars['ID']['input']
}

export type QueryTeamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  email_Icontains?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  firstName_Icontains?: InputMaybe<Scalars['String']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  lastName_Icontains?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  username_Icontains?: InputMaybe<Scalars['String']['input']>
}

export type QueryWorkloadDefinitionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  isArchived?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryWorkloadRunsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  containerRuntimeRequirements_Label?: InputMaybe<Scalars['String']['input']>
  createdBy_FirstName_Icontains?: InputMaybe<Scalars['String']['input']>
  createdBy_Id?: InputMaybe<Scalars['ID']['input']>
  createdBy_LastName_Icontains?: InputMaybe<Scalars['String']['input']>
  createdBy_Username?: InputMaybe<Scalars['String']['input']>
  createdBy_Username_Icontains?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  lifecycleStatus?: InputMaybe<Scalars['String']['input']>
  lifecycleStatus_In?: InputMaybe<Array<InputMaybe<WorkloadRunLifecycleStatus>>>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
  team_Id?: InputMaybe<Scalars['ID']['input']>
  team_Name?: InputMaybe<Scalars['String']['input']>
  workloadDefinitionId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryWorkloadRuntimeCapacityArgs = {
  containerRuntimeId: Scalars['ID']['input']
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type ReservationConnection = {
  __typename?: 'ReservationConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReservationEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `Reservation` and its cursor. */
export type ReservationEdge = {
  __typename?: 'ReservationEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<ReservationType>
}

/** An enumeration. */
export enum ReservationStatus {
  /** canceled */
  Canceled = 'CANCELED',
  /** complete */
  Complete = 'COMPLETE',
  /** error */
  Error = 'ERROR',
  /** new */
  New = 'NEW',
  /** pending */
  Pending = 'PENDING',
  /** releasing */
  Releasing = 'RELEASING',
  /** reserved */
  Reserved = 'RESERVED',
  /** scheduled */
  Scheduled = 'SCHEDULED',
  /** searching */
  Searching = 'SEARCHING',
}

export type ReservationType = Node & {
  __typename?: 'ReservationType'
  createdAt: Scalars['DateTime']['output']
  createdBy: UserType
  endedAt: Scalars['DateTime']['output']
  /** The ID of the object */
  id: Scalars['ID']['output']
  pk?: Maybe<Scalars['String']['output']>
  requestedAmountOfHardwareModel: Scalars['Int']['output']
  requestedHardwareModel?: Maybe<HardwareModelType>
  reservedHardware: Array<HardwareType>
  startedAt: Scalars['DateTime']['output']
  status: ReservationStatus
  statusMessage?: Maybe<Scalars['String']['output']>
  team?: Maybe<TeamType>
  updatedAt: Scalars['DateTime']['output']
}

export type ReservationTypeConnection = {
  __typename?: 'ReservationTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<ReservationTypeEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `ReservationType` and its cursor. */
export type ReservationTypeEdge = {
  __typename?: 'ReservationTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node: ReservationType
}

export type SshKeyCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  key: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type SshKeyCreatePayload = {
  __typename?: 'SSHKeyCreatePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  sshKey: SshKeyType
}

export type SshKeyDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type SshKeyDeletePayload = {
  __typename?: 'SSHKeyDeletePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok: Scalars['Boolean']['output']
}

export type SshKeyType = Node & {
  __typename?: 'SSHKeyType'
  createdAt: Scalars['DateTime']['output']
  /** The ID of the object */
  id: Scalars['ID']['output']
  key: Scalars['String']['output']
  name: Scalars['String']['output']
  owner: UserType
  pk?: Maybe<Scalars['String']['output']>
}

export type SshKeyTypeConnection = {
  __typename?: 'SSHKeyTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<SshKeyTypeEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `SSHKeyType` and its cursor. */
export type SshKeyTypeEdge = {
  __typename?: 'SSHKeyTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node: SshKeyType
}

export type ServerConnection = {
  __typename?: 'ServerConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ServerEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

export type ServerCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  fileSystems?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  name: Scalars['String']['input']
  openstackFlavorId: Scalars['ID']['input']
  openstackImageId: Scalars['ID']['input']
  teamId: Scalars['ID']['input']
  vnicType?: InputMaybe<VnicType>
}

export type ServerCreatePayload = {
  __typename?: 'ServerCreatePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  server: OpenStackServerType
}

export type ServerDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type ServerDeletePayload = {
  __typename?: 'ServerDeletePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok: Scalars['Boolean']['output']
}

/** A Relay edge containing a `Server` and its cursor. */
export type ServerEdge = {
  __typename?: 'ServerEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<OpenStackServerType>
}

export type ServerHardRebootInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type ServerHardRebootPayload = {
  __typename?: 'ServerHardRebootPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  server: OpenStackServerType
}

export type ServerShutoffInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type ServerShutoffPayload = {
  __typename?: 'ServerShutoffPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  server: OpenStackServerType
}

export type ServerSoftRebootInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type ServerSoftRebootPayload = {
  __typename?: 'ServerSoftRebootPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  server: OpenStackServerType
}

export type ServerStartInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type ServerStartPayload = {
  __typename?: 'ServerStartPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  server: OpenStackServerType
}

export type SignupInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  password: Scalars['String']['input']
  signupCode: Scalars['String']['input']
  username: Scalars['String']['input']
}

export type SignupPayload = {
  __typename?: 'SignupPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  token: TokenType
}

export type TeamAddUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  teamId: Scalars['ID']['input']
  userId: Scalars['String']['input']
}

export type TeamAddUserPayload = {
  __typename?: 'TeamAddUserPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  team: TeamType
}

export type TeamChangeUserGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  groupId: Scalars['ID']['input']
  teamId: Scalars['ID']['input']
  userId: Scalars['String']['input']
}

export type TeamChangeUserGroupPayload = {
  __typename?: 'TeamChangeUserGroupPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  team: TeamType
}

export type TeamCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
}

export type TeamCreatePayload = {
  __typename?: 'TeamCreatePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  team: TeamType
}

export type TeamMembershipType = {
  __typename?: 'TeamMembershipType'
  id: Scalars['ID']['output']
  role: Scalars['String']['output']
  slug: Scalars['String']['output']
}

export type TeamRemoveUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  teamId: Scalars['ID']['input']
  userId: Scalars['String']['input']
}

export type TeamRemoveUserPayload = {
  __typename?: 'TeamRemoveUserPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  team: TeamType
}

export type TeamType = Node & {
  __typename?: 'TeamType'
  adminGroup?: Maybe<GroupType>
  clusterSet?: Maybe<ClusterConnection>
  createdAt: Scalars['DateTime']['output']
  filesystemSet?: Maybe<FileSystemConnection>
  group?: Maybe<GroupType>
  hardwareSet?: Maybe<HardwareConnection>
  /** The ID of the object */
  id: Scalars['ID']['output']
  ingressrouteSet?: Maybe<IngressRouteConnection>
  isActive: Scalars['Boolean']['output']
  isHardwareEnabled: Scalars['Boolean']['output']
  isKubernetesEnabled: Scalars['Boolean']['output']
  isStorageEnabled: Scalars['Boolean']['output']
  isVmEnabled: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  openstackserverSet?: Maybe<ServerConnection>
  reservationSet?: Maybe<ReservationConnection>
  slug: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  workloaddefinitionSet?: Maybe<WorkloadDefinitionConnection>
  workloadrunSet?: Maybe<WorkloadRunConnection>
}

export type TeamTypeClusterSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeFilesystemSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeHardwareSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeIngressrouteSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeOpenstackserverSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeReservationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeWorkloaddefinitionSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeWorkloadrunSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeConnection = {
  __typename?: 'TeamTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<TeamTypeEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `TeamType` and its cursor. */
export type TeamTypeEdge = {
  __typename?: 'TeamTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node: TeamType
}

export type TokenType = Node & {
  __typename?: 'TokenType'
  createdAt: Scalars['DateTime']['output']
  /** The ID of the object */
  id: Scalars['ID']['output']
  key: Scalars['String']['output']
  keyName: Scalars['String']['output']
  pk?: Maybe<Scalars['String']['output']>
  user: UserType
}

export type UserType = Node & {
  __typename?: 'UserType'
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  /** The ID of the object */
  id: Scalars['ID']['output']
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean']['output']
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean']['output']
  lastName: Scalars['String']['output']
  pk?: Maybe<Scalars['String']['output']>
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String']['output']
}

export type UserTypeConnection = {
  __typename?: 'UserTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<UserTypeEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `UserType` and its cursor. */
export type UserTypeEdge = {
  __typename?: 'UserTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node: UserType
}

export type UserUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  id: Scalars['ID']['input']
  lastName: Scalars['String']['input']
}

export type UserUpdatePayload = {
  __typename?: 'UserUpdatePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  user: UserType
}

/** An enumeration. */
export enum VnicType {
  Direct = 'DIRECT',
  Normal = 'NORMAL',
}

export type WhoamiType = {
  __typename?: 'WhoamiType'
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean']['output']
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean']['output']
  lastName: Scalars['String']['output']
  teams: Array<TeamMembershipType>
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String']['output']
}

export type WorkloadDefinitionConnection = {
  __typename?: 'WorkloadDefinitionConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WorkloadDefinitionEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

export type WorkloadDefinitionCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  teamId: Scalars['ID']['input']
}

export type WorkloadDefinitionCreatePayload = {
  __typename?: 'WorkloadDefinitionCreatePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  workloadDefinition: WorkloadDefinitionType
}

export type WorkloadDefinitionDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type WorkloadDefinitionDeletePayload = {
  __typename?: 'WorkloadDefinitionDeletePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok: Scalars['Boolean']['output']
}

/** A Relay edge containing a `WorkloadDefinition` and its cursor. */
export type WorkloadDefinitionEdge = {
  __typename?: 'WorkloadDefinitionEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<WorkloadDefinitionType>
}

export type WorkloadDefinitionType = Node & {
  __typename?: 'WorkloadDefinitionType'
  createdBy: UserType
  environmentVariables: Scalars['JSONString']['output']
  fileSystems: FileSystemTypeConnection
  /** The ID of the object */
  id: Scalars['ID']['output']
  /** Workload Definition for which Runs the IngressRoute is intended */
  ingressrouteSet: IngressRouteTypeConnection
  livenessPath?: Maybe<Scalars['String']['output']>
  livenessPort?: Maybe<Scalars['Int']['output']>
  memoryLimit: Scalars['String']['output']
  metricsPath?: Maybe<Scalars['String']['output']>
  metricsPort?: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  pk?: Maybe<Scalars['String']['output']>
  readinessPath?: Maybe<Scalars['String']['output']>
  readinessPort?: Maybe<Scalars['Int']['output']>
  registryIsConfigured: Scalars['Boolean']['output']
  runsActiveCount: Scalars['Int']['output']
  slug: Scalars['String']['output']
}

export type WorkloadDefinitionTypeFileSystemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdBy_Id?: InputMaybe<Scalars['ID']['input']>
  createdBy_Username?: InputMaybe<Scalars['String']['input']>
  createdBy_Username_Icontains?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  name_Icontains?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  status_In?: InputMaybe<Array<InputMaybe<FileSystemStatus>>>
  team_Name?: InputMaybe<Scalars['String']['input']>
}

export type WorkloadDefinitionTypeIngressrouteSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type WorkloadDefinitionTypeConnection = {
  __typename?: 'WorkloadDefinitionTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<WorkloadDefinitionTypeEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `WorkloadDefinitionType` and its cursor. */
export type WorkloadDefinitionTypeEdge = {
  __typename?: 'WorkloadDefinitionTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node: WorkloadDefinitionType
}

export type WorkloadDefinitionUnarchiveInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type WorkloadDefinitionUnarchivePayload = {
  __typename?: 'WorkloadDefinitionUnarchivePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok: Scalars['Boolean']['output']
}

export type WorkloadDefinitionUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  environmentVariables?: InputMaybe<Scalars['JSONString']['input']>
  fileSystems?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  id: Scalars['ID']['input']
  imageRegistrySecrets?: InputMaybe<Scalars['JSONString']['input']>
  livenessPath?: InputMaybe<Scalars['String']['input']>
  livenessPort?: InputMaybe<Scalars['Int']['input']>
  memoryLimit: Scalars['String']['input']
  metricsPath?: InputMaybe<Scalars['String']['input']>
  metricsPort?: InputMaybe<Scalars['Int']['input']>
  readinessPath?: InputMaybe<Scalars['String']['input']>
  readinessPort?: InputMaybe<Scalars['Int']['input']>
}

export type WorkloadDefinitionUpdatePayload = {
  __typename?: 'WorkloadDefinitionUpdatePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  workloadDefinition: WorkloadDefinitionType
}

export type WorkloadRunCommentUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  comments: Scalars['String']['input']
  id: Scalars['ID']['input']
}

export type WorkloadRunCommentUpdatePayload = {
  __typename?: 'WorkloadRunCommentUpdatePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  workloadRun: WorkloadRunType
}

export type WorkloadRunConnection = {
  __typename?: 'WorkloadRunConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WorkloadRunEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

export type WorkloadRunCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  containerArgs?: InputMaybe<Scalars['String']['input']>
  containerFullImagePath: Scalars['String']['input']
  containerPorts?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  containerRuntimeRequirementId: Scalars['ID']['input']
  requireTeamHardware: Scalars['Boolean']['input']
  servicePort?: InputMaybe<Scalars['Int']['input']>
  stickySessions: Scalars['Boolean']['input']
  stickySessionsCookieName?: InputMaybe<Scalars['String']['input']>
  teamId: Scalars['ID']['input']
  workloadDefinitionId: Scalars['ID']['input']
  workloadRunScale: Scalars['Int']['input']
}

export type WorkloadRunCreatePayload = {
  __typename?: 'WorkloadRunCreatePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  workloadRun: WorkloadRunType
}

export type WorkloadRunDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  teamId: Scalars['ID']['input']
}

export type WorkloadRunDeletePayload = {
  __typename?: 'WorkloadRunDeletePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  workloadRun: WorkloadRunType
}

/** A Relay edge containing a `WorkloadRun` and its cursor. */
export type WorkloadRunEdge = {
  __typename?: 'WorkloadRunEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<WorkloadRunType>
}

/** An enumeration. */
export enum WorkloadRunLifecycleStatus {
  /** complete */
  Complete = 'COMPLETE',
  /** deleted */
  Deleted = 'DELETED',
  /** error */
  Error = 'ERROR',
  /** failed */
  Failed = 'FAILED',
  /** fulfilling_reservations */
  FulfillingReservations = 'FULFILLING_RESERVATIONS',
  /** generating_yaml */
  GeneratingYaml = 'GENERATING_YAML',
  /** new */
  New = 'NEW',
  /** pending */
  Pending = 'PENDING',
  /** release_reservation */
  ReleaseReservations = 'RELEASE_RESERVATIONS',
  /** request_reservations */
  RequestReservations = 'REQUEST_RESERVATIONS',
  /** request_start */
  RequestStart = 'REQUEST_START',
  /** request_stop */
  RequestStop = 'REQUEST_STOP',
  /** reservation_failed */
  ReservationFailed = 'RESERVATION_FAILED',
  /** running */
  Running = 'RUNNING',
  /** scheduled */
  Scheduled = 'SCHEDULED',
  /** stopping */
  Stopping = 'STOPPING',
}

export type WorkloadRunRestartInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type WorkloadRunRestartPayload = {
  __typename?: 'WorkloadRunRestartPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  workloadRun: WorkloadRunType
}

export type WorkloadRunType = Node & {
  __typename?: 'WorkloadRunType'
  /** Optional notes from the owners */
  comments?: Maybe<Scalars['String']['output']>
  /** This is user defined! */
  containerArgs?: Maybe<Scalars['String']['output']>
  /** This should be the container's shell, please use /bin/bash but could be /bin/sh */
  containerCommand?: Maybe<Scalars['String']['output']>
  containerFullImagePath: Scalars['String']['output']
  containerPorts: Scalars['JSONString']['output']
  containerRuntimeRequirements: ContainerRuntimeRequirementsType
  createdAt: Scalars['DateTime']['output']
  createdBy: UserType
  /** The ID of the object */
  id: Scalars['ID']['output']
  ingressRouteMatch?: Maybe<Scalars['String']['output']>
  ingressrouteSet: Array<IngressRouteType>
  lifecycleStatus: WorkloadRunLifecycleStatus
  namespace: Scalars['String']['output']
  pk: Scalars['String']['output']
  /** Constrain deployment to team hardware only. */
  requireTeamHardware: Scalars['Boolean']['output']
  runNumber: Scalars['Int']['output']
  serviceName?: Maybe<Scalars['String']['output']>
  servicePort?: Maybe<Scalars['Int']['output']>
  statusMessage?: Maybe<Scalars['String']['output']>
  /** Enable sticky sessions for this ingressroute */
  stickySessions: Scalars['Boolean']['output']
  /** Override the default (randomized) sticky session cookie name with this */
  stickySessionsCookieName?: Maybe<Scalars['String']['output']>
  team: TeamType
  updatedAt: Scalars['DateTime']['output']
  workloadDefinitionSlug: Scalars['String']['output']
  workloadNodeName?: Maybe<Scalars['String']['output']>
  /** Number of containers in Running state */
  workloadReplicas: Scalars['Int']['output']
  workloadRunScale: Scalars['Int']['output']
  workloadType: Scalars['String']['output']
}

export type WorkloadRunTypeConnection = {
  __typename?: 'WorkloadRunTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<WorkloadRunTypeEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `WorkloadRunType` and its cursor. */
export type WorkloadRunTypeEdge = {
  __typename?: 'WorkloadRunTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node: WorkloadRunType
}

export type WorkloadRuntimeCapacityType = {
  __typename?: 'WorkloadRuntimeCapacityType'
  maximumScale?: Maybe<Scalars['Int']['output']>
  singleInstanceCapacity?: Maybe<Scalars['Int']['output']>
}

export type AuditLogFragmentFragment = {
  __typename?: 'AuditLogType'
  id: string
  timestamp: any
  teamName?: string | null
  username: string
  resourceType: AuditLogResourceType
  resourceId?: any | null
  resourceName?: string | null
  action: AuditLogAction
  context?: string | null
}

export type AuditlogsQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['ID']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  user?: InputMaybe<Scalars['String']['input']>
  team?: InputMaybe<Scalars['String']['input']>
  resourceName?: InputMaybe<Scalars['String']['input']>
  resourceType?: InputMaybe<Array<AuditLogResourceType> | AuditLogResourceType>
  action?: InputMaybe<Array<AuditLogAction> | AuditLogAction>
}>

export type AuditlogsQuery = {
  __typename?: 'Query'
  auditlogs: {
    __typename?: 'AuditLogTypeConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'AuditLogTypeEdge'
      node: {
        __typename?: 'AuditLogType'
        id: string
        timestamp: any
        teamName?: string | null
        username: string
        resourceType: AuditLogResourceType
        resourceId?: any | null
        resourceName?: string | null
        action: AuditLogAction
        context?: string | null
      }
    }>
  }
}

export type LoginMutationVariables = Exact<{
  input: LoginInput
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login: {
    __typename?: 'LoginPayload'
    token: {
      __typename?: 'TokenType'
      id: string
      key: string
      keyName: string
      createdAt: any
      user: { __typename?: 'UserType'; username: string }
    }
  }
}

export type LogoutMutationVariables = Exact<{
  input: LogoutInput
}>

export type LogoutMutation = {
  __typename?: 'Mutation'
  logout: { __typename?: 'LogoutPayload'; ok: boolean }
}

export type SignupMutationVariables = Exact<{
  input: SignupInput
}>

export type SignupMutation = {
  __typename?: 'Mutation'
  signup: {
    __typename?: 'SignupPayload'
    token: {
      __typename?: 'TokenType'
      id: string
      key: string
      keyName: string
      createdAt: any
      user: { __typename?: 'UserType'; username: string }
    }
  }
}

export type PasswordChangeMutationVariables = Exact<{
  input: PasswordChangeInput
}>

export type PasswordChangeMutation = {
  __typename?: 'Mutation'
  passwordChange: { __typename?: 'PasswordChangePayload'; ok: boolean }
}

export type ClusterFragmentFragment = {
  __typename?: 'ClusterType'
  pk?: string | null
  createdAt: any
  updatedAt: any
  slug: string
}

export type ClustersQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
}>

export type ClustersQuery = {
  __typename?: 'Query'
  clusters: {
    __typename?: 'ClusterTypeConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'ClusterTypeEdge'
      node: {
        __typename?: 'ClusterType'
        pk?: string | null
        createdAt: any
        updatedAt: any
        slug: string
      }
    }>
  }
}

export type ContainerRuntimeRequirementsQueryVariables = Exact<{
  orderBy?: InputMaybe<Scalars['String']['input']>
}>

export type ContainerRuntimeRequirementsQuery = {
  __typename?: 'Query'
  containerRuntimeRequirements: {
    __typename?: 'ContainerRuntimeRequirementsTypeConnection'
    edges: Array<{
      __typename?: 'ContainerRuntimeRequirementsTypeEdge'
      node: {
        __typename?: 'ContainerRuntimeRequirementsType'
        id: string
        label: string
        availability: ContainerRuntimeRequirementsAvailability
        resourceName: string
      }
    }>
  }
}

export type FileSystemFragmentFragment = {
  __typename?: 'FileSystemType'
  id: string
  pk?: string | null
  createdAt: any
  updatedAt: any
  name: string
  slug: string
  status: FileSystemStatus
  capacity: string
  errorMessage?: string | null
  createdBy: {
    __typename?: 'UserType'
    id: string
    firstName: string
    lastName: string
    username: string
  }
  team: { __typename?: 'TeamType'; name: string }
}

export type FileSystemsQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Array<FileSystemStatus> | FileSystemStatus>
  name?: InputMaybe<Scalars['String']['input']>
  createdByUsername?: InputMaybe<Scalars['String']['input']>
  teamName?: InputMaybe<Scalars['String']['input']>
}>

export type FileSystemsQuery = {
  __typename?: 'Query'
  fileSystems: {
    __typename?: 'FileSystemTypeConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'FileSystemTypeEdge'
      node: {
        __typename?: 'FileSystemType'
        id: string
        pk?: string | null
        createdAt: any
        updatedAt: any
        name: string
        slug: string
        status: FileSystemStatus
        capacity: string
        errorMessage?: string | null
        createdBy: {
          __typename?: 'UserType'
          id: string
          firstName: string
          lastName: string
          username: string
        }
        team: { __typename?: 'TeamType'; name: string }
      }
    }>
  }
}

export type FileSystemCreateMutationVariables = Exact<{
  input: FileSystemCreateInput
}>

export type FileSystemCreateMutation = {
  __typename?: 'Mutation'
  fileSystemCreate: {
    __typename?: 'FileSystemCreatePayload'
    fileSystem: {
      __typename?: 'FileSystemType'
      id: string
      pk?: string | null
      createdAt: any
      updatedAt: any
      name: string
      slug: string
      status: FileSystemStatus
      capacity: string
      errorMessage?: string | null
      createdBy: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      }
      team: { __typename?: 'TeamType'; name: string }
    }
  }
}

export type FileSystemDeleteMutationVariables = Exact<{
  input: FileSystemDeleteInput
}>

export type FileSystemDeleteMutation = {
  __typename?: 'Mutation'
  fileSystemDelete: {
    __typename?: 'FileSystemDeletePayload'
    fileSystem: {
      __typename?: 'FileSystemType'
      id: string
      pk?: string | null
      createdAt: any
      updatedAt: any
      name: string
      slug: string
      status: FileSystemStatus
      capacity: string
      errorMessage?: string | null
      createdBy: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      }
      team: { __typename?: 'TeamType'; name: string }
    }
  }
}

export type FlavorFragmentFragment = {
  __typename?: 'OpenStackFlavorType'
  id: string
  pk?: string | null
  createdAt: any
  updatedAt: any
  name: string
  slug: string
  vnicType: OpenStackFlavorVnicType
}

export type FlavorsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  availability?: InputMaybe<Scalars['String']['input']>
}>

export type FlavorsQuery = {
  __typename?: 'Query'
  flavors: {
    __typename?: 'OpenStackFlavorTypeConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'OpenStackFlavorTypeEdge'
      node: {
        __typename?: 'OpenStackFlavorType'
        id: string
        pk?: string | null
        createdAt: any
        updatedAt: any
        name: string
        slug: string
        vnicType: OpenStackFlavorVnicType
      }
    }>
  }
}

export type HardwareFragmentFragment = {
  __typename?: 'HardwareType'
  pk?: string | null
  updatedAt: any
  teamName?: string | null
  slug: string
  isHealthy: boolean
  isQuarantined: boolean
  canBeReserved: boolean
  isReserved: boolean
  hardwareModel?: { __typename?: 'HardwareModelType'; modelName: string } | null
}

export type HardwaresQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  isHealthy?: InputMaybe<Scalars['Boolean']['input']>
  isQuarantined?: InputMaybe<Scalars['Boolean']['input']>
  teamName?: InputMaybe<Scalars['String']['input']>
  hardwareModelName?: InputMaybe<Scalars['String']['input']>
}>

export type HardwaresQuery = {
  __typename?: 'Query'
  hardwares: {
    __typename?: 'HardwareTypeConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'HardwareTypeEdge'
      node: {
        __typename?: 'HardwareType'
        pk?: string | null
        updatedAt: any
        teamName?: string | null
        slug: string
        isHealthy: boolean
        isQuarantined: boolean
        canBeReserved: boolean
        isReserved: boolean
        hardwareModel?: {
          __typename?: 'HardwareModelType'
          modelName: string
        } | null
      }
    }>
  }
}

export type IngressRouteFragmentFragment = {
  __typename?: 'IngressRouteType'
  id: string
  url: string
  workloadRun?: {
    __typename?: 'WorkloadRunType'
    id: string
    namespace: string
  } | null
}

export type IngressRoutesQueryVariables = Exact<{
  workloadDefinitionId: Scalars['ID']['input']
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}>

export type IngressRoutesQuery = {
  __typename?: 'Query'
  ingressRoutes: {
    __typename?: 'IngressRouteTypeConnection'
    edges: Array<{
      __typename?: 'IngressRouteTypeEdge'
      node: {
        __typename?: 'IngressRouteType'
        id: string
        url: string
        workloadRun?: {
          __typename?: 'WorkloadRunType'
          id: string
          namespace: string
        } | null
      }
    }>
  }
}

export type IngressRouteCreateMutationVariables = Exact<{
  input: IngressRouteCreateInput
}>

export type IngressRouteCreateMutation = {
  __typename?: 'Mutation'
  ingressRouteCreate: { __typename?: 'IngressRouteCreatePayload'; ok: boolean }
}

export type IngressRouteDeleteMutationVariables = Exact<{
  input: IngressRouteDeleteInput
}>

export type IngressRouteDeleteMutation = {
  __typename?: 'Mutation'
  ingressRouteDelete: { __typename?: 'IngressRouteDeletePayload'; ok: boolean }
}

export type IngressRouteAssignMutationVariables = Exact<{
  input: IngressRouteAssignInput
}>

export type IngressRouteAssignMutation = {
  __typename?: 'Mutation'
  ingressRouteAssign: { __typename?: 'IngressRouteAssignPayload'; ok: boolean }
}

export type OpenStackImageFragmentFragment = {
  __typename?: 'OpenStackImageType'
  id: string
  pk?: string | null
  createdAt: any
  updatedAt: any
  name: string
  slug: string
}

export type OpenStackImagesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  availability?: InputMaybe<Scalars['String']['input']>
}>

export type OpenStackImagesQuery = {
  __typename?: 'Query'
  openStackImages: {
    __typename?: 'OpenStackImageTypeConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'OpenStackImageTypeEdge'
      node: {
        __typename?: 'OpenStackImageType'
        id: string
        pk?: string | null
        createdAt: any
        updatedAt: any
        name: string
        slug: string
      }
    }>
  }
}

export type ReservationFragmentFragment = {
  __typename?: 'ReservationType'
  id: string
  pk?: string | null
  createdAt: any
  updatedAt: any
  requestedAmountOfHardwareModel: number
  status: ReservationStatus
  statusMessage?: string | null
  startedAt: any
  endedAt: any
  createdBy: {
    __typename?: 'UserType'
    id: string
    firstName: string
    lastName: string
    username: string
  }
  team?: { __typename?: 'TeamType'; name: string } | null
  requestedHardwareModel?: {
    __typename?: 'HardwareModelType'
    modelName: string
    modelIdentifier: string
  } | null
  reservedHardware: Array<{
    __typename?: 'HardwareType'
    id: string
    pk?: string | null
    slug: string
  }>
}

export type ReservationsQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  createdBy?: InputMaybe<Scalars['String']['input']>
  team?: InputMaybe<Scalars['String']['input']>
  hardwareModel?: InputMaybe<Scalars['String']['input']>
}>

export type ReservationsQuery = {
  __typename?: 'Query'
  reservations: {
    __typename?: 'ReservationTypeConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'ReservationTypeEdge'
      node: {
        __typename?: 'ReservationType'
        id: string
        pk?: string | null
        createdAt: any
        updatedAt: any
        requestedAmountOfHardwareModel: number
        status: ReservationStatus
        statusMessage?: string | null
        startedAt: any
        endedAt: any
        createdBy: {
          __typename?: 'UserType'
          id: string
          firstName: string
          lastName: string
          username: string
        }
        team?: { __typename?: 'TeamType'; name: string } | null
        requestedHardwareModel?: {
          __typename?: 'HardwareModelType'
          modelName: string
          modelIdentifier: string
        } | null
        reservedHardware: Array<{
          __typename?: 'HardwareType'
          id: string
          pk?: string | null
          slug: string
        }>
      }
    }>
  }
}

export type ServerFragmentFragment = {
  __typename?: 'OpenStackServerType'
  id: string
  pk?: string | null
  createdAt: any
  updatedAt: any
  name: string
  slug: string
  status: OpenStackServerStatus
  teamId: string
  teamName: string
  openstackFloatingIp?: string | null
  computeHost?: string | null
  errorMessage?: string | null
  openstackImage: {
    __typename?: 'OpenStackImageType'
    name: string
    defaultSshUser: string
  }
  openstackFlavor: { __typename?: 'OpenStackFlavorType'; name: string }
  createdBy: {
    __typename?: 'UserType'
    id: string
    firstName: string
    lastName: string
    username: string
  }
}

export type ServersQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Array<OpenStackServerStatus> | OpenStackServerStatus>
  createdBy?: InputMaybe<Scalars['String']['input']>
  computeHost?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  ip?: InputMaybe<Scalars['String']['input']>
  teamName?: InputMaybe<Scalars['String']['input']>
  flavor?: InputMaybe<Scalars['String']['input']>
  image?: InputMaybe<Scalars['String']['input']>
}>

export type ServersQuery = {
  __typename?: 'Query'
  servers: {
    __typename?: 'OpenStackServerTypeConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'OpenStackServerTypeEdge'
      node: {
        __typename?: 'OpenStackServerType'
        id: string
        pk?: string | null
        createdAt: any
        updatedAt: any
        name: string
        slug: string
        status: OpenStackServerStatus
        teamId: string
        teamName: string
        openstackFloatingIp?: string | null
        computeHost?: string | null
        errorMessage?: string | null
        openstackImage: {
          __typename?: 'OpenStackImageType'
          name: string
          defaultSshUser: string
        }
        openstackFlavor: { __typename?: 'OpenStackFlavorType'; name: string }
        createdBy: {
          __typename?: 'UserType'
          id: string
          firstName: string
          lastName: string
          username: string
        }
      }
    }>
  }
}

export type ServerCreateMutationVariables = Exact<{
  input: ServerCreateInput
}>

export type ServerCreateMutation = {
  __typename?: 'Mutation'
  serverCreate: {
    __typename?: 'ServerCreatePayload'
    server: {
      __typename?: 'OpenStackServerType'
      id: string
      pk?: string | null
      createdAt: any
      updatedAt: any
      name: string
      slug: string
      status: OpenStackServerStatus
      teamId: string
      teamName: string
      openstackFloatingIp?: string | null
      computeHost?: string | null
      errorMessage?: string | null
      openstackImage: {
        __typename?: 'OpenStackImageType'
        name: string
        defaultSshUser: string
      }
      openstackFlavor: { __typename?: 'OpenStackFlavorType'; name: string }
      createdBy: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      }
    }
  }
}

export type ServerDeleteMutationVariables = Exact<{
  input: ServerDeleteInput
}>

export type ServerDeleteMutation = {
  __typename?: 'Mutation'
  serverDelete: { __typename?: 'ServerDeletePayload'; ok: boolean }
}

export type ServerStartMutationVariables = Exact<{
  input: ServerStartInput
}>

export type ServerStartMutation = {
  __typename?: 'Mutation'
  serverStart: {
    __typename?: 'ServerStartPayload'
    server: {
      __typename?: 'OpenStackServerType'
      id: string
      pk?: string | null
      createdAt: any
      updatedAt: any
      name: string
      slug: string
      status: OpenStackServerStatus
      teamId: string
      teamName: string
      openstackFloatingIp?: string | null
      computeHost?: string | null
      errorMessage?: string | null
      openstackImage: {
        __typename?: 'OpenStackImageType'
        name: string
        defaultSshUser: string
      }
      openstackFlavor: { __typename?: 'OpenStackFlavorType'; name: string }
      createdBy: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      }
    }
  }
}

export type ServerShutoffMutationVariables = Exact<{
  input: ServerShutoffInput
}>

export type ServerShutoffMutation = {
  __typename?: 'Mutation'
  serverShutoff: {
    __typename?: 'ServerShutoffPayload'
    server: {
      __typename?: 'OpenStackServerType'
      id: string
      pk?: string | null
      createdAt: any
      updatedAt: any
      name: string
      slug: string
      status: OpenStackServerStatus
      teamId: string
      teamName: string
      openstackFloatingIp?: string | null
      computeHost?: string | null
      errorMessage?: string | null
      openstackImage: {
        __typename?: 'OpenStackImageType'
        name: string
        defaultSshUser: string
      }
      openstackFlavor: { __typename?: 'OpenStackFlavorType'; name: string }
      createdBy: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      }
    }
  }
}

export type ServerSoftRebootMutationVariables = Exact<{
  input: ServerSoftRebootInput
}>

export type ServerSoftRebootMutation = {
  __typename?: 'Mutation'
  serverSoftReboot: {
    __typename?: 'ServerSoftRebootPayload'
    server: {
      __typename?: 'OpenStackServerType'
      id: string
      pk?: string | null
      createdAt: any
      updatedAt: any
      name: string
      slug: string
      status: OpenStackServerStatus
      teamId: string
      teamName: string
      openstackFloatingIp?: string | null
      computeHost?: string | null
      errorMessage?: string | null
      openstackImage: {
        __typename?: 'OpenStackImageType'
        name: string
        defaultSshUser: string
      }
      openstackFlavor: { __typename?: 'OpenStackFlavorType'; name: string }
      createdBy: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      }
    }
  }
}

export type ServerHardRebootMutationVariables = Exact<{
  input: ServerHardRebootInput
}>

export type ServerHardRebootMutation = {
  __typename?: 'Mutation'
  serverHardReboot: {
    __typename?: 'ServerHardRebootPayload'
    server: {
      __typename?: 'OpenStackServerType'
      id: string
      pk?: string | null
      createdAt: any
      updatedAt: any
      name: string
      slug: string
      status: OpenStackServerStatus
      teamId: string
      teamName: string
      openstackFloatingIp?: string | null
      computeHost?: string | null
      errorMessage?: string | null
      openstackImage: {
        __typename?: 'OpenStackImageType'
        name: string
        defaultSshUser: string
      }
      openstackFlavor: { __typename?: 'OpenStackFlavorType'; name: string }
      createdBy: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      }
    }
  }
}

export type SshKeyFragmentFragment = {
  __typename?: 'SSHKeyType'
  id: string
  pk?: string | null
  createdAt: any
  name: string
  key: string
  owner: { __typename?: 'UserType'; id: string }
}

export type SshKeysQueryVariables = Exact<{ [key: string]: never }>

export type SshKeysQuery = {
  __typename?: 'Query'
  sshKeys: {
    __typename?: 'SSHKeyTypeConnection'
    edges: Array<{
      __typename?: 'SSHKeyTypeEdge'
      node: {
        __typename?: 'SSHKeyType'
        id: string
        pk?: string | null
        createdAt: any
        name: string
        key: string
        owner: { __typename?: 'UserType'; id: string }
      }
    }>
  }
}

export type SshKeyCreateMutationVariables = Exact<{
  input: SshKeyCreateInput
}>

export type SshKeyCreateMutation = {
  __typename?: 'Mutation'
  sshKeyCreate: {
    __typename?: 'SSHKeyCreatePayload'
    sshKey: {
      __typename?: 'SSHKeyType'
      id: string
      pk?: string | null
      createdAt: any
      name: string
      key: string
      owner: { __typename?: 'UserType'; id: string }
    }
  }
}

export type SshKeyDeleteMutationVariables = Exact<{
  input: SshKeyDeleteInput
}>

export type SshKeyDeleteMutation = {
  __typename?: 'Mutation'
  sshKeyDelete: { __typename?: 'SSHKeyDeletePayload'; ok: boolean }
}

export type TeamFragmentFragment = {
  __typename?: 'TeamType'
  id: string
  name: string
  slug: string
  isActive: boolean
  isKubernetesEnabled: boolean
  isHardwareEnabled: boolean
  isStorageEnabled: boolean
  isVmEnabled: boolean
}

export type TeamBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type TeamBySlugQuery = {
  __typename?: 'Query'
  teams: {
    __typename?: 'TeamTypeConnection'
    edges: Array<{
      __typename?: 'TeamTypeEdge'
      node: {
        __typename?: 'TeamType'
        id: string
        name: string
        slug: string
        isActive: boolean
        isKubernetesEnabled: boolean
        isHardwareEnabled: boolean
        isStorageEnabled: boolean
        isVmEnabled: boolean
      }
    }>
  }
}

export type TeamGroupsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type TeamGroupsQuery = {
  __typename?: 'Query'
  team: {
    __typename?: 'TeamType'
    adminGroup?: {
      __typename?: 'GroupType'
      id: string
      name: string
      userSet: {
        __typename?: 'UserTypeConnection'
        edges: Array<{
          __typename?: 'UserTypeEdge'
          node: {
            __typename?: 'UserType'
            id: string
            username: string
            firstName: string
            lastName: string
            isActive: boolean
          }
        }>
      }
    } | null
    group?: {
      __typename?: 'GroupType'
      id: string
      name: string
      userSet: {
        __typename?: 'UserTypeConnection'
        edges: Array<{
          __typename?: 'UserTypeEdge'
          node: {
            __typename?: 'UserType'
            id: string
            username: string
            firstName: string
            lastName: string
            isActive: boolean
          }
        }>
      }
    } | null
  }
}

export type TeamDropdownQueryVariables = Exact<{ [key: string]: never }>

export type TeamDropdownQuery = {
  __typename?: 'Query'
  teams: {
    __typename?: 'TeamTypeConnection'
    edges: Array<{
      __typename?: 'TeamTypeEdge'
      node: { __typename?: 'TeamType'; id: string; slug: string; name: string }
    }>
  }
}

export type TeamCreateMutationVariables = Exact<{
  input: TeamCreateInput
}>

export type TeamCreateMutation = {
  __typename?: 'Mutation'
  teamCreate: {
    __typename?: 'TeamCreatePayload'
    team: {
      __typename?: 'TeamType'
      id: string
      name: string
      slug: string
      isActive: boolean
      isKubernetesEnabled: boolean
      isHardwareEnabled: boolean
      isStorageEnabled: boolean
      isVmEnabled: boolean
    }
  }
}

export type TeamChangeUserGroupMutationVariables = Exact<{
  input: TeamChangeUserGroupInput
}>

export type TeamChangeUserGroupMutation = {
  __typename?: 'Mutation'
  teamChangeUserGroup: {
    __typename?: 'TeamChangeUserGroupPayload'
    team: { __typename?: 'TeamType'; id: string }
  }
}

export type TeamAddUserMutationVariables = Exact<{
  input: TeamAddUserInput
}>

export type TeamAddUserMutation = {
  __typename?: 'Mutation'
  teamAddUser: {
    __typename?: 'TeamAddUserPayload'
    team: { __typename?: 'TeamType'; id: string }
  }
}

export type TeamRemoveUserMutationVariables = Exact<{
  input: TeamRemoveUserInput
}>

export type TeamRemoveUserMutation = {
  __typename?: 'Mutation'
  teamRemoveUser: {
    __typename?: 'TeamRemoveUserPayload'
    team: { __typename?: 'TeamType'; id: string }
  }
}

export type DashboardWorkloadRunsQueryVariables = Exact<{
  createdByUsername?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  lifecycleStatus?: InputMaybe<
    Array<WorkloadRunLifecycleStatus> | WorkloadRunLifecycleStatus
  >
}>

export type DashboardWorkloadRunsQuery = {
  __typename?: 'Query'
  workloadRuns: {
    __typename?: 'WorkloadRunTypeConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'WorkloadRunTypeEdge'
      node: {
        __typename?: 'WorkloadRunType'
        workloadDefinitionSlug: string
        lifecycleStatus: WorkloadRunLifecycleStatus
        namespace: string
        ingressRouteMatch?: string | null
        team: { __typename?: 'TeamType'; name: string; slug: string }
        containerRuntimeRequirements: {
          __typename?: 'ContainerRuntimeRequirementsType'
          label: string
        }
      }
    }>
  }
}

export type DashboardVirtualMachinesQueryVariables = Exact<{
  createdByUsername?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Array<OpenStackServerStatus> | OpenStackServerStatus>
}>

export type DashboardVirtualMachinesQuery = {
  __typename?: 'Query'
  servers: {
    __typename?: 'OpenStackServerTypeConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'OpenStackServerTypeEdge'
      node: {
        __typename?: 'OpenStackServerType'
        name: string
        status: OpenStackServerStatus
        teamName: string
        teamSlug: string
        openstackFloatingIp?: string | null
        openstackImage: {
          __typename?: 'OpenStackImageType'
          name: string
          defaultSshUser: string
        }
        openstackFlavor: { __typename?: 'OpenStackFlavorType'; name: string }
      }
    }>
  }
}

export type BasicUserFragmentFragment = {
  __typename?: 'UserType'
  id: string
  pk?: string | null
  username: string
  email: string
  firstName: string
  lastName: string
  isSuperuser: boolean
  isActive: boolean
}

export type UsersQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UsersQuery = {
  __typename?: 'Query'
  users: {
    __typename?: 'UserTypeConnection'
    edges: Array<{
      __typename?: 'UserTypeEdge'
      node: {
        __typename?: 'UserType'
        id: string
        pk?: string | null
        username: string
        email: string
        firstName: string
        lastName: string
        isSuperuser: boolean
        isActive: boolean
      }
    }>
  }
}

export type WhoamiQueryVariables = Exact<{ [key: string]: never }>

export type WhoamiQuery = {
  __typename?: 'Query'
  whoami: {
    __typename?: 'WhoamiType'
    id: string
    email: string
    username: string
    firstName: string
    lastName: string
    isSuperuser: boolean
    isActive: boolean
    teams: Array<{
      __typename?: 'TeamMembershipType'
      id: string
      slug: string
      role: string
    }>
  }
}

export type UserUpdateMutationVariables = Exact<{
  input: UserUpdateInput
}>

export type UserUpdateMutation = {
  __typename?: 'Mutation'
  userUpdate: {
    __typename?: 'UserUpdatePayload'
    user: {
      __typename?: 'UserType'
      id: string
      pk?: string | null
      username: string
      email: string
      firstName: string
      lastName: string
      isSuperuser: boolean
      isActive: boolean
    }
  }
}

export type WorkloadDefinitionFragmentFragment = {
  __typename?: 'WorkloadDefinitionType'
  id: string
  name: string
  slug: string
  environmentVariables: any
  registryIsConfigured: boolean
  memoryLimit: string
  metricsPort?: number | null
  metricsPath?: string | null
  livenessPort?: number | null
  livenessPath?: string | null
  readinessPort?: number | null
  readinessPath?: string | null
  createdBy: {
    __typename?: 'UserType'
    id: string
    firstName: string
    lastName: string
  }
  fileSystems: {
    __typename?: 'FileSystemTypeConnection'
    edges: Array<{
      __typename?: 'FileSystemTypeEdge'
      node: {
        __typename?: 'FileSystemType'
        id: string
        slug: string
        capacity: string
      }
    }>
  }
  ingressrouteSet: {
    __typename?: 'IngressRouteTypeConnection'
    edges: Array<{
      __typename?: 'IngressRouteTypeEdge'
      node: {
        __typename?: 'IngressRouteType'
        id: string
        url: string
        workloadRun?: {
          __typename?: 'WorkloadRunType'
          id: string
          namespace: string
        } | null
      }
    }>
  }
}

export type WorkloadDefinitionsQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  isArchived?: InputMaybe<Scalars['Boolean']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}>

export type WorkloadDefinitionsQuery = {
  __typename?: 'Query'
  workloadDefinitions: {
    __typename?: 'WorkloadDefinitionTypeConnection'
    edges: Array<{
      __typename?: 'WorkloadDefinitionTypeEdge'
      node: {
        __typename?: 'WorkloadDefinitionType'
        id: string
        name: string
        slug: string
        environmentVariables: any
        registryIsConfigured: boolean
        memoryLimit: string
        metricsPort?: number | null
        metricsPath?: string | null
        livenessPort?: number | null
        livenessPath?: string | null
        readinessPort?: number | null
        readinessPath?: string | null
        createdBy: {
          __typename?: 'UserType'
          id: string
          firstName: string
          lastName: string
        }
        fileSystems: {
          __typename?: 'FileSystemTypeConnection'
          edges: Array<{
            __typename?: 'FileSystemTypeEdge'
            node: {
              __typename?: 'FileSystemType'
              id: string
              slug: string
              capacity: string
            }
          }>
        }
        ingressrouteSet: {
          __typename?: 'IngressRouteTypeConnection'
          edges: Array<{
            __typename?: 'IngressRouteTypeEdge'
            node: {
              __typename?: 'IngressRouteType'
              id: string
              url: string
              workloadRun?: {
                __typename?: 'WorkloadRunType'
                id: string
                namespace: string
              } | null
            }
          }>
        }
      }
    }>
  }
}

export type WorkloadDefinitionNavigationQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  isArchived?: InputMaybe<Scalars['Boolean']['input']>
}>

export type WorkloadDefinitionNavigationQuery = {
  __typename?: 'Query'
  workloadDefinitions: {
    __typename?: 'WorkloadDefinitionTypeConnection'
    edges: Array<{
      __typename?: 'WorkloadDefinitionTypeEdge'
      node: {
        __typename?: 'WorkloadDefinitionType'
        id: string
        name: string
        slug: string
        runsActiveCount: number
      }
    }>
  }
}

export type WorkloadDefinitionCreateMutationVariables = Exact<{
  input: WorkloadDefinitionCreateInput
}>

export type WorkloadDefinitionCreateMutation = {
  __typename?: 'Mutation'
  workloadDefinitionCreate: {
    __typename?: 'WorkloadDefinitionCreatePayload'
    workloadDefinition: {
      __typename?: 'WorkloadDefinitionType'
      id: string
      name: string
      slug: string
      environmentVariables: any
      registryIsConfigured: boolean
      memoryLimit: string
      metricsPort?: number | null
      metricsPath?: string | null
      livenessPort?: number | null
      livenessPath?: string | null
      readinessPort?: number | null
      readinessPath?: string | null
      createdBy: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
      }
      fileSystems: {
        __typename?: 'FileSystemTypeConnection'
        edges: Array<{
          __typename?: 'FileSystemTypeEdge'
          node: {
            __typename?: 'FileSystemType'
            id: string
            slug: string
            capacity: string
          }
        }>
      }
      ingressrouteSet: {
        __typename?: 'IngressRouteTypeConnection'
        edges: Array<{
          __typename?: 'IngressRouteTypeEdge'
          node: {
            __typename?: 'IngressRouteType'
            id: string
            url: string
            workloadRun?: {
              __typename?: 'WorkloadRunType'
              id: string
              namespace: string
            } | null
          }
        }>
      }
    }
  }
}

export type WorkloadDefinitionUpdateMutationVariables = Exact<{
  input: WorkloadDefinitionUpdateInput
}>

export type WorkloadDefinitionUpdateMutation = {
  __typename?: 'Mutation'
  workloadDefinitionUpdate: {
    __typename?: 'WorkloadDefinitionUpdatePayload'
    workloadDefinition: {
      __typename?: 'WorkloadDefinitionType'
      id: string
      name: string
      slug: string
      environmentVariables: any
      registryIsConfigured: boolean
      memoryLimit: string
      metricsPort?: number | null
      metricsPath?: string | null
      livenessPort?: number | null
      livenessPath?: string | null
      readinessPort?: number | null
      readinessPath?: string | null
      createdBy: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
      }
      fileSystems: {
        __typename?: 'FileSystemTypeConnection'
        edges: Array<{
          __typename?: 'FileSystemTypeEdge'
          node: {
            __typename?: 'FileSystemType'
            id: string
            slug: string
            capacity: string
          }
        }>
      }
      ingressrouteSet: {
        __typename?: 'IngressRouteTypeConnection'
        edges: Array<{
          __typename?: 'IngressRouteTypeEdge'
          node: {
            __typename?: 'IngressRouteType'
            id: string
            url: string
            workloadRun?: {
              __typename?: 'WorkloadRunType'
              id: string
              namespace: string
            } | null
          }
        }>
      }
    }
  }
}

export type WorkloadDefinitionDeleteMutationVariables = Exact<{
  input: WorkloadDefinitionDeleteInput
}>

export type WorkloadDefinitionDeleteMutation = {
  __typename?: 'Mutation'
  workloadDefinitionDelete: {
    __typename?: 'WorkloadDefinitionDeletePayload'
    ok: boolean
  }
}

export type WorkloadDefinitionUnarchiveMutationVariables = Exact<{
  input: WorkloadDefinitionUnarchiveInput
}>

export type WorkloadDefinitionUnarchiveMutation = {
  __typename?: 'Mutation'
  workloadDefinitionUnarchive: {
    __typename?: 'WorkloadDefinitionUnarchivePayload'
    ok: boolean
  }
}

export type WorkloadRunFragmentFragment = {
  __typename?: 'WorkloadRunType'
  pk: string
  id: string
  createdAt: any
  updatedAt: any
  workloadDefinitionSlug: string
  workloadType: string
  runNumber: number
  lifecycleStatus: WorkloadRunLifecycleStatus
  namespace: string
  workloadRunScale: number
  containerFullImagePath: string
  containerCommand?: string | null
  containerArgs?: string | null
  containerPorts: any
  workloadNodeName?: string | null
  serviceName?: string | null
  servicePort?: number | null
  workloadReplicas: number
  statusMessage?: string | null
  ingressRouteMatch?: string | null
  requireTeamHardware: boolean
  stickySessions: boolean
  comments?: string | null
  stickySessionsCookieName?: string | null
  team: { __typename?: 'TeamType'; id: string; name: string; slug: string }
  createdBy: {
    __typename?: 'UserType'
    id: string
    firstName: string
    lastName: string
    username: string
  }
  containerRuntimeRequirements: {
    __typename?: 'ContainerRuntimeRequirementsType'
    id: string
    label: string
  }
  ingressrouteSet: Array<{ __typename?: 'IngressRouteType'; url: string }>
}

export type WorkloadRunsQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  workloadDefinitionId?: InputMaybe<Scalars['ID']['input']>
  lifecycleStatus?: InputMaybe<
    Array<WorkloadRunLifecycleStatus> | WorkloadRunLifecycleStatus
  >
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  username?: InputMaybe<Scalars['String']['input']>
  teamName?: InputMaybe<Scalars['String']['input']>
  runtimeRequirement?: InputMaybe<Scalars['String']['input']>
}>

export type WorkloadRunsQuery = {
  __typename?: 'Query'
  workloadRuns: {
    __typename?: 'WorkloadRunTypeConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'WorkloadRunTypeEdge'
      node: {
        __typename?: 'WorkloadRunType'
        pk: string
        id: string
        createdAt: any
        updatedAt: any
        workloadDefinitionSlug: string
        workloadType: string
        runNumber: number
        lifecycleStatus: WorkloadRunLifecycleStatus
        namespace: string
        workloadRunScale: number
        containerFullImagePath: string
        containerCommand?: string | null
        containerArgs?: string | null
        containerPorts: any
        workloadNodeName?: string | null
        serviceName?: string | null
        servicePort?: number | null
        workloadReplicas: number
        statusMessage?: string | null
        ingressRouteMatch?: string | null
        requireTeamHardware: boolean
        stickySessions: boolean
        comments?: string | null
        stickySessionsCookieName?: string | null
        team: {
          __typename?: 'TeamType'
          id: string
          name: string
          slug: string
        }
        createdBy: {
          __typename?: 'UserType'
          id: string
          firstName: string
          lastName: string
          username: string
        }
        containerRuntimeRequirements: {
          __typename?: 'ContainerRuntimeRequirementsType'
          id: string
          label: string
        }
        ingressrouteSet: Array<{ __typename?: 'IngressRouteType'; url: string }>
      }
    }>
  }
}

export type WorkloadRuntimeCapacityQueryVariables = Exact<{
  containerRuntimeId: Scalars['ID']['input']
  teamId?: InputMaybe<Scalars['ID']['input']>
}>

export type WorkloadRuntimeCapacityQuery = {
  __typename?: 'Query'
  workloadRuntimeCapacity: {
    __typename?: 'WorkloadRuntimeCapacityType'
    maximumScale?: number | null
    singleInstanceCapacity?: number | null
  }
}

export type WorkloadRunCreateMutationVariables = Exact<{
  input: WorkloadRunCreateInput
}>

export type WorkloadRunCreateMutation = {
  __typename?: 'Mutation'
  workloadRunCreate: {
    __typename?: 'WorkloadRunCreatePayload'
    workloadRun: {
      __typename?: 'WorkloadRunType'
      pk: string
      id: string
      createdAt: any
      updatedAt: any
      workloadDefinitionSlug: string
      workloadType: string
      runNumber: number
      lifecycleStatus: WorkloadRunLifecycleStatus
      namespace: string
      workloadRunScale: number
      containerFullImagePath: string
      containerCommand?: string | null
      containerArgs?: string | null
      containerPorts: any
      workloadNodeName?: string | null
      serviceName?: string | null
      servicePort?: number | null
      workloadReplicas: number
      statusMessage?: string | null
      ingressRouteMatch?: string | null
      requireTeamHardware: boolean
      stickySessions: boolean
      comments?: string | null
      stickySessionsCookieName?: string | null
      team: { __typename?: 'TeamType'; id: string; name: string; slug: string }
      createdBy: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      }
      containerRuntimeRequirements: {
        __typename?: 'ContainerRuntimeRequirementsType'
        id: string
        label: string
      }
      ingressrouteSet: Array<{ __typename?: 'IngressRouteType'; url: string }>
    }
  }
}

export type WorkloadRunCommentUpdateMutationVariables = Exact<{
  input: WorkloadRunCommentUpdateInput
}>

export type WorkloadRunCommentUpdateMutation = {
  __typename?: 'Mutation'
  workloadRunCommentUpdate: {
    __typename?: 'WorkloadRunCommentUpdatePayload'
    workloadRun: {
      __typename?: 'WorkloadRunType'
      pk: string
      id: string
      createdAt: any
      updatedAt: any
      workloadDefinitionSlug: string
      workloadType: string
      runNumber: number
      lifecycleStatus: WorkloadRunLifecycleStatus
      namespace: string
      workloadRunScale: number
      containerFullImagePath: string
      containerCommand?: string | null
      containerArgs?: string | null
      containerPorts: any
      workloadNodeName?: string | null
      serviceName?: string | null
      servicePort?: number | null
      workloadReplicas: number
      statusMessage?: string | null
      ingressRouteMatch?: string | null
      requireTeamHardware: boolean
      stickySessions: boolean
      comments?: string | null
      stickySessionsCookieName?: string | null
      team: { __typename?: 'TeamType'; id: string; name: string; slug: string }
      createdBy: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      }
      containerRuntimeRequirements: {
        __typename?: 'ContainerRuntimeRequirementsType'
        id: string
        label: string
      }
      ingressrouteSet: Array<{ __typename?: 'IngressRouteType'; url: string }>
    }
  }
}

export type WorkloadRunRestartMutationVariables = Exact<{
  input: WorkloadRunRestartInput
}>

export type WorkloadRunRestartMutation = {
  __typename?: 'Mutation'
  workloadRunRestart: {
    __typename?: 'WorkloadRunRestartPayload'
    workloadRun: {
      __typename?: 'WorkloadRunType'
      pk: string
      id: string
      createdAt: any
      updatedAt: any
      workloadDefinitionSlug: string
      workloadType: string
      runNumber: number
      lifecycleStatus: WorkloadRunLifecycleStatus
      namespace: string
      workloadRunScale: number
      containerFullImagePath: string
      containerCommand?: string | null
      containerArgs?: string | null
      containerPorts: any
      workloadNodeName?: string | null
      serviceName?: string | null
      servicePort?: number | null
      workloadReplicas: number
      statusMessage?: string | null
      ingressRouteMatch?: string | null
      requireTeamHardware: boolean
      stickySessions: boolean
      comments?: string | null
      stickySessionsCookieName?: string | null
      team: { __typename?: 'TeamType'; id: string; name: string; slug: string }
      createdBy: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      }
      containerRuntimeRequirements: {
        __typename?: 'ContainerRuntimeRequirementsType'
        id: string
        label: string
      }
      ingressrouteSet: Array<{ __typename?: 'IngressRouteType'; url: string }>
    }
  }
}

export type WorkloadRunDeleteMutationVariables = Exact<{
  input: WorkloadRunDeleteInput
}>

export type WorkloadRunDeleteMutation = {
  __typename?: 'Mutation'
  workloadRunDelete: {
    __typename?: 'WorkloadRunDeletePayload'
    workloadRun: {
      __typename?: 'WorkloadRunType'
      pk: string
      id: string
      createdAt: any
      updatedAt: any
      workloadDefinitionSlug: string
      workloadType: string
      runNumber: number
      lifecycleStatus: WorkloadRunLifecycleStatus
      namespace: string
      workloadRunScale: number
      containerFullImagePath: string
      containerCommand?: string | null
      containerArgs?: string | null
      containerPorts: any
      workloadNodeName?: string | null
      serviceName?: string | null
      servicePort?: number | null
      workloadReplicas: number
      statusMessage?: string | null
      ingressRouteMatch?: string | null
      requireTeamHardware: boolean
      stickySessions: boolean
      comments?: string | null
      stickySessionsCookieName?: string | null
      team: { __typename?: 'TeamType'; id: string; name: string; slug: string }
      createdBy: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      }
      containerRuntimeRequirements: {
        __typename?: 'ContainerRuntimeRequirementsType'
        id: string
        label: string
      }
      ingressrouteSet: Array<{ __typename?: 'IngressRouteType'; url: string }>
    }
  }
}

export type KubeconfigQueryVariables = Exact<{ [key: string]: never }>

export type KubeconfigQuery = {
  __typename?: 'Query'
  kubeconfig: { __typename?: 'KubeconfigType'; kubeconfig: string }
}

export const AuditLogFragmentFragmentDoc = `
    fragment AuditLogFragment on AuditLogType {
  id
  timestamp
  teamName
  username
  resourceType
  resourceId
  resourceName
  action
  context
}
    `
export const ClusterFragmentFragmentDoc = `
    fragment ClusterFragment on ClusterType {
  pk
  createdAt
  updatedAt
  slug
}
    `
export const FileSystemFragmentFragmentDoc = `
    fragment FileSystemFragment on FileSystemType {
  id
  pk
  createdAt
  updatedAt
  name
  slug
  status
  capacity
  createdBy {
    id
    firstName
    lastName
    username
  }
  team {
    name
  }
  errorMessage
}
    `
export const FlavorFragmentFragmentDoc = `
    fragment FlavorFragment on OpenStackFlavorType {
  id
  pk
  createdAt
  updatedAt
  name
  slug
  vnicType
}
    `
export const HardwareFragmentFragmentDoc = `
    fragment HardwareFragment on HardwareType {
  pk
  updatedAt
  teamName
  slug
  isHealthy
  isQuarantined
  canBeReserved
  isReserved
  hardwareModel {
    modelName
  }
}
    `
export const IngressRouteFragmentFragmentDoc = `
    fragment IngressRouteFragment on IngressRouteType {
  id
  url
  workloadRun {
    id
    namespace
  }
}
    `
export const OpenStackImageFragmentFragmentDoc = `
    fragment OpenStackImageFragment on OpenStackImageType {
  id
  pk
  createdAt
  updatedAt
  name
  slug
}
    `
export const ReservationFragmentFragmentDoc = `
    fragment ReservationFragment on ReservationType {
  id
  pk
  createdAt
  updatedAt
  createdBy {
    id
    firstName
    lastName
    username
  }
  team {
    name
  }
  requestedHardwareModel {
    modelName
    modelIdentifier
  }
  requestedAmountOfHardwareModel
  reservedHardware {
    id
    pk
    slug
  }
  status
  statusMessage
  startedAt
  endedAt
}
    `
export const ServerFragmentFragmentDoc = `
    fragment ServerFragment on OpenStackServerType {
  id
  pk
  createdAt
  updatedAt
  name
  slug
  status
  teamId
  teamName
  openstackImage {
    name
    defaultSshUser
  }
  openstackFlavor {
    name
  }
  openstackFloatingIp
  createdBy {
    id
    firstName
    lastName
    username
  }
  computeHost
  errorMessage
}
    `
export const SshKeyFragmentFragmentDoc = `
    fragment SSHKeyFragment on SSHKeyType {
  id
  pk
  createdAt
  name
  key
  owner {
    id
  }
}
    `
export const TeamFragmentFragmentDoc = `
    fragment TeamFragment on TeamType {
  id
  name
  slug
  isActive
  isKubernetesEnabled
  isHardwareEnabled
  isStorageEnabled
  isVmEnabled
}
    `
export const BasicUserFragmentFragmentDoc = `
    fragment BasicUserFragment on UserType {
  id
  pk
  username
  email
  firstName
  lastName
  isSuperuser
  isActive
}
    `
export const WorkloadDefinitionFragmentFragmentDoc = `
    fragment WorkloadDefinitionFragment on WorkloadDefinitionType {
  id
  name
  slug
  createdBy {
    id
    firstName
    lastName
  }
  environmentVariables
  registryIsConfigured
  fileSystems {
    edges {
      node {
        id
        slug
        capacity
      }
    }
  }
  memoryLimit
  ingressrouteSet {
    edges {
      node {
        id
        url
        workloadRun {
          id
          namespace
        }
      }
    }
  }
  metricsPort
  metricsPath
  livenessPort
  livenessPath
  readinessPort
  readinessPath
}
    `
export const WorkloadRunFragmentFragmentDoc = `
    fragment WorkloadRunFragment on WorkloadRunType {
  pk
  id
  createdAt
  updatedAt
  team {
    id
    name
    slug
  }
  workloadDefinitionSlug
  createdBy {
    id
    firstName
    lastName
    username
  }
  containerRuntimeRequirements {
    id
    label
  }
  ingressrouteSet {
    url
  }
  workloadType
  runNumber
  lifecycleStatus
  namespace
  workloadRunScale
  containerFullImagePath
  containerCommand
  containerArgs
  containerPorts
  workloadNodeName
  serviceName
  servicePort
  workloadReplicas
  statusMessage
  ingressRouteMatch
  requireTeamHardware
  stickySessions
  comments
  stickySessionsCookieName
}
    `
export const AuditlogsDocument = `
    query auditlogs($teamId: ID, $userId: ID, $first: Int, $offset: Int, $orderBy: String, $user: String, $team: String, $resourceName: String, $resourceType: [AuditLogResourceType!], $action: [AuditLogAction!]) {
  auditlogs(
    teamId: $teamId
    userId: $userId
    first: $first
    offset: $offset
    orderBy: $orderBy
    token_User_Username_Icontains: $user
    team_Name_Icontains: $team
    resourceType_In: $resourceType
    resourceName_Icontains: $resourceName
    action_In: $action
  ) {
    totalCount
    edges {
      node {
        ...AuditLogFragment
      }
    }
  }
}
    ${AuditLogFragmentFragmentDoc}`

export const useAuditlogsQuery = <TData = AuditlogsQuery, TError = unknown>(
  variables?: AuditlogsQueryVariables,
  options?: UseQueryOptions<AuditlogsQuery, TError, TData>
) => {
  return useQuery<AuditlogsQuery, TError, TData>(
    variables === undefined ? ['auditlogs'] : ['auditlogs', variables],
    useFetchData<AuditlogsQuery, AuditlogsQueryVariables>(
      AuditlogsDocument
    ).bind(null, variables),
    options
  )
}

export const LoginDocument = `
    mutation login($input: LoginInput!) {
  login(input: $input) {
    token {
      id
      user {
        username
      }
      key
      keyName
      createdAt
    }
  }
}
    `

export const useLoginMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    LoginMutation,
    TError,
    LoginMutationVariables,
    TContext
  >
) => {
  return useMutation<LoginMutation, TError, LoginMutationVariables, TContext>(
    ['login'],
    useFetchData<LoginMutation, LoginMutationVariables>(LoginDocument),
    options
  )
}

export const LogoutDocument = `
    mutation logout($input: LogoutInput!) {
  logout(input: $input) {
    ok
  }
}
    `

export const useLogoutMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    LogoutMutation,
    TError,
    LogoutMutationVariables,
    TContext
  >
) => {
  return useMutation<LogoutMutation, TError, LogoutMutationVariables, TContext>(
    ['logout'],
    useFetchData<LogoutMutation, LogoutMutationVariables>(LogoutDocument),
    options
  )
}

export const SignupDocument = `
    mutation signup($input: SignupInput!) {
  signup(input: $input) {
    token {
      id
      user {
        username
      }
      key
      keyName
      createdAt
    }
  }
}
    `

export const useSignupMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SignupMutation,
    TError,
    SignupMutationVariables,
    TContext
  >
) => {
  return useMutation<SignupMutation, TError, SignupMutationVariables, TContext>(
    ['signup'],
    useFetchData<SignupMutation, SignupMutationVariables>(SignupDocument),
    options
  )
}

export const PasswordChangeDocument = `
    mutation passwordChange($input: PasswordChangeInput!) {
  passwordChange(input: $input) {
    ok
  }
}
    `

export const usePasswordChangeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PasswordChangeMutation,
    TError,
    PasswordChangeMutationVariables,
    TContext
  >
) => {
  return useMutation<
    PasswordChangeMutation,
    TError,
    PasswordChangeMutationVariables,
    TContext
  >(
    ['passwordChange'],
    useFetchData<PasswordChangeMutation, PasswordChangeMutationVariables>(
      PasswordChangeDocument
    ),
    options
  )
}

export const ClustersDocument = `
    query clusters($teamId: ID, $first: Int, $offset: Int, $orderBy: String) {
  clusters(teamId: $teamId, first: $first, offset: $offset, orderBy: $orderBy) {
    totalCount
    edges {
      node {
        ...ClusterFragment
      }
    }
  }
}
    ${ClusterFragmentFragmentDoc}`

export const useClustersQuery = <TData = ClustersQuery, TError = unknown>(
  variables?: ClustersQueryVariables,
  options?: UseQueryOptions<ClustersQuery, TError, TData>
) => {
  return useQuery<ClustersQuery, TError, TData>(
    variables === undefined ? ['clusters'] : ['clusters', variables],
    useFetchData<ClustersQuery, ClustersQueryVariables>(ClustersDocument).bind(
      null,
      variables
    ),
    options
  )
}

export const ContainerRuntimeRequirementsDocument = `
    query containerRuntimeRequirements($orderBy: String) {
  containerRuntimeRequirements(orderBy: $orderBy) {
    edges {
      node {
        id
        label
        availability
        resourceName
      }
    }
  }
}
    `

export const useContainerRuntimeRequirementsQuery = <
  TData = ContainerRuntimeRequirementsQuery,
  TError = unknown,
>(
  variables?: ContainerRuntimeRequirementsQueryVariables,
  options?: UseQueryOptions<ContainerRuntimeRequirementsQuery, TError, TData>
) => {
  return useQuery<ContainerRuntimeRequirementsQuery, TError, TData>(
    variables === undefined
      ? ['containerRuntimeRequirements']
      : ['containerRuntimeRequirements', variables],
    useFetchData<
      ContainerRuntimeRequirementsQuery,
      ContainerRuntimeRequirementsQueryVariables
    >(ContainerRuntimeRequirementsDocument).bind(null, variables),
    options
  )
}

export const FileSystemsDocument = `
    query fileSystems($teamId: ID, $first: Int, $offset: Int, $orderBy: String, $status: [FileSystemStatus!], $name: String, $createdByUsername: String, $teamName: String) {
  fileSystems(
    teamId: $teamId
    first: $first
    offset: $offset
    orderBy: $orderBy
    status_In: $status
    name_Icontains: $name
    createdBy_Username_Icontains: $createdByUsername
    team_Name: $teamName
  ) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...FileSystemFragment
      }
    }
  }
}
    ${FileSystemFragmentFragmentDoc}`

export const useFileSystemsQuery = <TData = FileSystemsQuery, TError = unknown>(
  variables?: FileSystemsQueryVariables,
  options?: UseQueryOptions<FileSystemsQuery, TError, TData>
) => {
  return useQuery<FileSystemsQuery, TError, TData>(
    variables === undefined ? ['fileSystems'] : ['fileSystems', variables],
    useFetchData<FileSystemsQuery, FileSystemsQueryVariables>(
      FileSystemsDocument
    ).bind(null, variables),
    options
  )
}

export const FileSystemCreateDocument = `
    mutation fileSystemCreate($input: FileSystemCreateInput!) {
  fileSystemCreate(input: $input) {
    fileSystem {
      ...FileSystemFragment
    }
  }
}
    ${FileSystemFragmentFragmentDoc}`

export const useFileSystemCreateMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    FileSystemCreateMutation,
    TError,
    FileSystemCreateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    FileSystemCreateMutation,
    TError,
    FileSystemCreateMutationVariables,
    TContext
  >(
    ['fileSystemCreate'],
    useFetchData<FileSystemCreateMutation, FileSystemCreateMutationVariables>(
      FileSystemCreateDocument
    ),
    options
  )
}

export const FileSystemDeleteDocument = `
    mutation fileSystemDelete($input: FileSystemDeleteInput!) {
  fileSystemDelete(input: $input) {
    fileSystem {
      ...FileSystemFragment
    }
  }
}
    ${FileSystemFragmentFragmentDoc}`

export const useFileSystemDeleteMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    FileSystemDeleteMutation,
    TError,
    FileSystemDeleteMutationVariables,
    TContext
  >
) => {
  return useMutation<
    FileSystemDeleteMutation,
    TError,
    FileSystemDeleteMutationVariables,
    TContext
  >(
    ['fileSystemDelete'],
    useFetchData<FileSystemDeleteMutation, FileSystemDeleteMutationVariables>(
      FileSystemDeleteDocument
    ),
    options
  )
}

export const FlavorsDocument = `
    query flavors($first: Int, $offset: Int, $orderBy: String, $availability: String) {
  flavors(
    first: $first
    offset: $offset
    orderBy: $orderBy
    availability: $availability
  ) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...FlavorFragment
      }
    }
  }
}
    ${FlavorFragmentFragmentDoc}`

export const useFlavorsQuery = <TData = FlavorsQuery, TError = unknown>(
  variables?: FlavorsQueryVariables,
  options?: UseQueryOptions<FlavorsQuery, TError, TData>
) => {
  return useQuery<FlavorsQuery, TError, TData>(
    variables === undefined ? ['flavors'] : ['flavors', variables],
    useFetchData<FlavorsQuery, FlavorsQueryVariables>(FlavorsDocument).bind(
      null,
      variables
    ),
    options
  )
}

export const HardwaresDocument = `
    query hardwares($teamId: ID, $first: Int, $offset: Int, $orderBy: String, $name: String, $isHealthy: Boolean, $isQuarantined: Boolean, $teamName: String, $hardwareModelName: String) {
  hardwares(
    teamId: $teamId
    first: $first
    offset: $offset
    orderBy: $orderBy
    slug_Icontains: $name
    isHealthy: $isHealthy
    isQuarantined: $isQuarantined
    team_Name_Icontains: $teamName
    hardwareModel_ModelName_Icontains: $hardwareModelName
  ) {
    totalCount
    edges {
      node {
        ...HardwareFragment
      }
    }
  }
}
    ${HardwareFragmentFragmentDoc}`

export const useHardwaresQuery = <TData = HardwaresQuery, TError = unknown>(
  variables?: HardwaresQueryVariables,
  options?: UseQueryOptions<HardwaresQuery, TError, TData>
) => {
  return useQuery<HardwaresQuery, TError, TData>(
    variables === undefined ? ['hardwares'] : ['hardwares', variables],
    useFetchData<HardwaresQuery, HardwaresQueryVariables>(
      HardwaresDocument
    ).bind(null, variables),
    options
  )
}

export const IngressRoutesDocument = `
    query ingressRoutes($workloadDefinitionId: ID!, $first: Int, $offset: Int) {
  ingressRoutes(
    workloadDefinitionId: $workloadDefinitionId
    first: $first
    offset: $offset
  ) {
    edges {
      node {
        ...IngressRouteFragment
      }
    }
  }
}
    ${IngressRouteFragmentFragmentDoc}`

export const useIngressRoutesQuery = <
  TData = IngressRoutesQuery,
  TError = unknown,
>(
  variables: IngressRoutesQueryVariables,
  options?: UseQueryOptions<IngressRoutesQuery, TError, TData>
) => {
  return useQuery<IngressRoutesQuery, TError, TData>(
    ['ingressRoutes', variables],
    useFetchData<IngressRoutesQuery, IngressRoutesQueryVariables>(
      IngressRoutesDocument
    ).bind(null, variables),
    options
  )
}

export const IngressRouteCreateDocument = `
    mutation ingressRouteCreate($input: IngressRouteCreateInput!) {
  ingressRouteCreate(input: $input) {
    ok
  }
}
    `

export const useIngressRouteCreateMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    IngressRouteCreateMutation,
    TError,
    IngressRouteCreateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    IngressRouteCreateMutation,
    TError,
    IngressRouteCreateMutationVariables,
    TContext
  >(
    ['ingressRouteCreate'],
    useFetchData<
      IngressRouteCreateMutation,
      IngressRouteCreateMutationVariables
    >(IngressRouteCreateDocument),
    options
  )
}

export const IngressRouteDeleteDocument = `
    mutation ingressRouteDelete($input: IngressRouteDeleteInput!) {
  ingressRouteDelete(input: $input) {
    ok
  }
}
    `

export const useIngressRouteDeleteMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    IngressRouteDeleteMutation,
    TError,
    IngressRouteDeleteMutationVariables,
    TContext
  >
) => {
  return useMutation<
    IngressRouteDeleteMutation,
    TError,
    IngressRouteDeleteMutationVariables,
    TContext
  >(
    ['ingressRouteDelete'],
    useFetchData<
      IngressRouteDeleteMutation,
      IngressRouteDeleteMutationVariables
    >(IngressRouteDeleteDocument),
    options
  )
}

export const IngressRouteAssignDocument = `
    mutation ingressRouteAssign($input: IngressRouteAssignInput!) {
  ingressRouteAssign(input: $input) {
    ok
  }
}
    `

export const useIngressRouteAssignMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    IngressRouteAssignMutation,
    TError,
    IngressRouteAssignMutationVariables,
    TContext
  >
) => {
  return useMutation<
    IngressRouteAssignMutation,
    TError,
    IngressRouteAssignMutationVariables,
    TContext
  >(
    ['ingressRouteAssign'],
    useFetchData<
      IngressRouteAssignMutation,
      IngressRouteAssignMutationVariables
    >(IngressRouteAssignDocument),
    options
  )
}

export const OpenStackImagesDocument = `
    query openStackImages($first: Int, $offset: Int, $orderBy: String, $availability: String) {
  openStackImages(
    first: $first
    offset: $offset
    orderBy: $orderBy
    availability: $availability
  ) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...OpenStackImageFragment
      }
    }
  }
}
    ${OpenStackImageFragmentFragmentDoc}`

export const useOpenStackImagesQuery = <
  TData = OpenStackImagesQuery,
  TError = unknown,
>(
  variables?: OpenStackImagesQueryVariables,
  options?: UseQueryOptions<OpenStackImagesQuery, TError, TData>
) => {
  return useQuery<OpenStackImagesQuery, TError, TData>(
    variables === undefined
      ? ['openStackImages']
      : ['openStackImages', variables],
    useFetchData<OpenStackImagesQuery, OpenStackImagesQueryVariables>(
      OpenStackImagesDocument
    ).bind(null, variables),
    options
  )
}

export const ReservationsDocument = `
    query reservations($teamId: ID, $first: Int, $offset: Int, $orderBy: String, $status: String, $createdBy: String, $team: String, $hardwareModel: String) {
  reservations(
    teamId: $teamId
    first: $first
    offset: $offset
    orderBy: $orderBy
    status: $status
    createdBy_Username_Icontains: $createdBy
    team_Name_Icontains: $team
    requestedHardwareModel_ModelIdentifier_Icontains: $hardwareModel
  ) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...ReservationFragment
      }
    }
  }
}
    ${ReservationFragmentFragmentDoc}`

export const useReservationsQuery = <
  TData = ReservationsQuery,
  TError = unknown,
>(
  variables?: ReservationsQueryVariables,
  options?: UseQueryOptions<ReservationsQuery, TError, TData>
) => {
  return useQuery<ReservationsQuery, TError, TData>(
    variables === undefined ? ['reservations'] : ['reservations', variables],
    useFetchData<ReservationsQuery, ReservationsQueryVariables>(
      ReservationsDocument
    ).bind(null, variables),
    options
  )
}

export const ServersDocument = `
    query servers($teamId: ID, $first: Int, $offset: Int, $orderBy: String, $status: [OpenStackServerStatus!], $createdBy: String, $computeHost: String, $name: String, $ip: String, $teamName: String, $flavor: String, $image: String) {
  servers(
    teamId: $teamId
    first: $first
    offset: $offset
    orderBy: $orderBy
    status_In: $status
    createdBy_Username_Icontains: $createdBy
    computeHost_Icontains: $computeHost
    name_Icontains: $name
    openstackFloatingIp_Icontains: $ip
    team_Name: $teamName
    openstackFlavor_Name: $flavor
    openstackImage_Name: $image
  ) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...ServerFragment
      }
    }
  }
}
    ${ServerFragmentFragmentDoc}`

export const useServersQuery = <TData = ServersQuery, TError = unknown>(
  variables?: ServersQueryVariables,
  options?: UseQueryOptions<ServersQuery, TError, TData>
) => {
  return useQuery<ServersQuery, TError, TData>(
    variables === undefined ? ['servers'] : ['servers', variables],
    useFetchData<ServersQuery, ServersQueryVariables>(ServersDocument).bind(
      null,
      variables
    ),
    options
  )
}

export const ServerCreateDocument = `
    mutation serverCreate($input: ServerCreateInput!) {
  serverCreate(input: $input) {
    server {
      ...ServerFragment
    }
  }
}
    ${ServerFragmentFragmentDoc}`

export const useServerCreateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ServerCreateMutation,
    TError,
    ServerCreateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ServerCreateMutation,
    TError,
    ServerCreateMutationVariables,
    TContext
  >(
    ['serverCreate'],
    useFetchData<ServerCreateMutation, ServerCreateMutationVariables>(
      ServerCreateDocument
    ),
    options
  )
}

export const ServerDeleteDocument = `
    mutation serverDelete($input: ServerDeleteInput!) {
  serverDelete(input: $input) {
    ok
  }
}
    `

export const useServerDeleteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ServerDeleteMutation,
    TError,
    ServerDeleteMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ServerDeleteMutation,
    TError,
    ServerDeleteMutationVariables,
    TContext
  >(
    ['serverDelete'],
    useFetchData<ServerDeleteMutation, ServerDeleteMutationVariables>(
      ServerDeleteDocument
    ),
    options
  )
}

export const ServerStartDocument = `
    mutation serverStart($input: ServerStartInput!) {
  serverStart(input: $input) {
    server {
      ...ServerFragment
    }
  }
}
    ${ServerFragmentFragmentDoc}`

export const useServerStartMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ServerStartMutation,
    TError,
    ServerStartMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ServerStartMutation,
    TError,
    ServerStartMutationVariables,
    TContext
  >(
    ['serverStart'],
    useFetchData<ServerStartMutation, ServerStartMutationVariables>(
      ServerStartDocument
    ),
    options
  )
}

export const ServerShutoffDocument = `
    mutation serverShutoff($input: ServerShutoffInput!) {
  serverShutoff(input: $input) {
    server {
      ...ServerFragment
    }
  }
}
    ${ServerFragmentFragmentDoc}`

export const useServerShutoffMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ServerShutoffMutation,
    TError,
    ServerShutoffMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ServerShutoffMutation,
    TError,
    ServerShutoffMutationVariables,
    TContext
  >(
    ['serverShutoff'],
    useFetchData<ServerShutoffMutation, ServerShutoffMutationVariables>(
      ServerShutoffDocument
    ),
    options
  )
}

export const ServerSoftRebootDocument = `
    mutation serverSoftReboot($input: ServerSoftRebootInput!) {
  serverSoftReboot(input: $input) {
    server {
      ...ServerFragment
    }
  }
}
    ${ServerFragmentFragmentDoc}`

export const useServerSoftRebootMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ServerSoftRebootMutation,
    TError,
    ServerSoftRebootMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ServerSoftRebootMutation,
    TError,
    ServerSoftRebootMutationVariables,
    TContext
  >(
    ['serverSoftReboot'],
    useFetchData<ServerSoftRebootMutation, ServerSoftRebootMutationVariables>(
      ServerSoftRebootDocument
    ),
    options
  )
}

export const ServerHardRebootDocument = `
    mutation serverHardReboot($input: ServerHardRebootInput!) {
  serverHardReboot(input: $input) {
    server {
      ...ServerFragment
    }
  }
}
    ${ServerFragmentFragmentDoc}`

export const useServerHardRebootMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ServerHardRebootMutation,
    TError,
    ServerHardRebootMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ServerHardRebootMutation,
    TError,
    ServerHardRebootMutationVariables,
    TContext
  >(
    ['serverHardReboot'],
    useFetchData<ServerHardRebootMutation, ServerHardRebootMutationVariables>(
      ServerHardRebootDocument
    ),
    options
  )
}

export const SshKeysDocument = `
    query sshKeys {
  sshKeys {
    edges {
      node {
        ...SSHKeyFragment
      }
    }
  }
}
    ${SshKeyFragmentFragmentDoc}`

export const useSshKeysQuery = <TData = SshKeysQuery, TError = unknown>(
  variables?: SshKeysQueryVariables,
  options?: UseQueryOptions<SshKeysQuery, TError, TData>
) => {
  return useQuery<SshKeysQuery, TError, TData>(
    variables === undefined ? ['sshKeys'] : ['sshKeys', variables],
    useFetchData<SshKeysQuery, SshKeysQueryVariables>(SshKeysDocument).bind(
      null,
      variables
    ),
    options
  )
}

export const SshKeyCreateDocument = `
    mutation sshKeyCreate($input: SSHKeyCreateInput!) {
  sshKeyCreate(input: $input) {
    sshKey {
      ...SSHKeyFragment
    }
  }
}
    ${SshKeyFragmentFragmentDoc}`

export const useSshKeyCreateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SshKeyCreateMutation,
    TError,
    SshKeyCreateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SshKeyCreateMutation,
    TError,
    SshKeyCreateMutationVariables,
    TContext
  >(
    ['sshKeyCreate'],
    useFetchData<SshKeyCreateMutation, SshKeyCreateMutationVariables>(
      SshKeyCreateDocument
    ),
    options
  )
}

export const SshKeyDeleteDocument = `
    mutation sshKeyDelete($input: SSHKeyDeleteInput!) {
  sshKeyDelete(input: $input) {
    ok
  }
}
    `

export const useSshKeyDeleteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SshKeyDeleteMutation,
    TError,
    SshKeyDeleteMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SshKeyDeleteMutation,
    TError,
    SshKeyDeleteMutationVariables,
    TContext
  >(
    ['sshKeyDelete'],
    useFetchData<SshKeyDeleteMutation, SshKeyDeleteMutationVariables>(
      SshKeyDeleteDocument
    ),
    options
  )
}

export const TeamBySlugDocument = `
    query teamBySlug($slug: String!) {
  teams(slug: $slug) {
    edges {
      node {
        ...TeamFragment
      }
    }
  }
}
    ${TeamFragmentFragmentDoc}`

export const useTeamBySlugQuery = <TData = TeamBySlugQuery, TError = unknown>(
  variables: TeamBySlugQueryVariables,
  options?: UseQueryOptions<TeamBySlugQuery, TError, TData>
) => {
  return useQuery<TeamBySlugQuery, TError, TData>(
    ['teamBySlug', variables],
    useFetchData<TeamBySlugQuery, TeamBySlugQueryVariables>(
      TeamBySlugDocument
    ).bind(null, variables),
    options
  )
}

export const TeamGroupsDocument = `
    query teamGroups($id: ID!) {
  team(id: $id) {
    adminGroup {
      id
      name
      userSet {
        edges {
          node {
            id
            username
            firstName
            lastName
            isActive
          }
        }
      }
    }
    group {
      id
      name
      userSet {
        edges {
          node {
            id
            username
            firstName
            lastName
            isActive
          }
        }
      }
    }
  }
}
    `

export const useTeamGroupsQuery = <TData = TeamGroupsQuery, TError = unknown>(
  variables: TeamGroupsQueryVariables,
  options?: UseQueryOptions<TeamGroupsQuery, TError, TData>
) => {
  return useQuery<TeamGroupsQuery, TError, TData>(
    ['teamGroups', variables],
    useFetchData<TeamGroupsQuery, TeamGroupsQueryVariables>(
      TeamGroupsDocument
    ).bind(null, variables),
    options
  )
}

export const TeamDropdownDocument = `
    query teamDropdown {
  teams {
    edges {
      node {
        id
        slug
        name
      }
    }
  }
}
    `

export const useTeamDropdownQuery = <
  TData = TeamDropdownQuery,
  TError = unknown,
>(
  variables?: TeamDropdownQueryVariables,
  options?: UseQueryOptions<TeamDropdownQuery, TError, TData>
) => {
  return useQuery<TeamDropdownQuery, TError, TData>(
    variables === undefined ? ['teamDropdown'] : ['teamDropdown', variables],
    useFetchData<TeamDropdownQuery, TeamDropdownQueryVariables>(
      TeamDropdownDocument
    ).bind(null, variables),
    options
  )
}

export const TeamCreateDocument = `
    mutation teamCreate($input: TeamCreateInput!) {
  teamCreate(input: $input) {
    team {
      ...TeamFragment
    }
  }
}
    ${TeamFragmentFragmentDoc}`

export const useTeamCreateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    TeamCreateMutation,
    TError,
    TeamCreateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    TeamCreateMutation,
    TError,
    TeamCreateMutationVariables,
    TContext
  >(
    ['teamCreate'],
    useFetchData<TeamCreateMutation, TeamCreateMutationVariables>(
      TeamCreateDocument
    ),
    options
  )
}

export const TeamChangeUserGroupDocument = `
    mutation teamChangeUserGroup($input: TeamChangeUserGroupInput!) {
  teamChangeUserGroup(input: $input) {
    team {
      id
    }
  }
}
    `

export const useTeamChangeUserGroupMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    TeamChangeUserGroupMutation,
    TError,
    TeamChangeUserGroupMutationVariables,
    TContext
  >
) => {
  return useMutation<
    TeamChangeUserGroupMutation,
    TError,
    TeamChangeUserGroupMutationVariables,
    TContext
  >(
    ['teamChangeUserGroup'],
    useFetchData<
      TeamChangeUserGroupMutation,
      TeamChangeUserGroupMutationVariables
    >(TeamChangeUserGroupDocument),
    options
  )
}

export const TeamAddUserDocument = `
    mutation teamAddUser($input: TeamAddUserInput!) {
  teamAddUser(input: $input) {
    team {
      id
    }
  }
}
    `

export const useTeamAddUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    TeamAddUserMutation,
    TError,
    TeamAddUserMutationVariables,
    TContext
  >
) => {
  return useMutation<
    TeamAddUserMutation,
    TError,
    TeamAddUserMutationVariables,
    TContext
  >(
    ['teamAddUser'],
    useFetchData<TeamAddUserMutation, TeamAddUserMutationVariables>(
      TeamAddUserDocument
    ),
    options
  )
}

export const TeamRemoveUserDocument = `
    mutation teamRemoveUser($input: TeamRemoveUserInput!) {
  teamRemoveUser(input: $input) {
    team {
      id
    }
  }
}
    `

export const useTeamRemoveUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    TeamRemoveUserMutation,
    TError,
    TeamRemoveUserMutationVariables,
    TContext
  >
) => {
  return useMutation<
    TeamRemoveUserMutation,
    TError,
    TeamRemoveUserMutationVariables,
    TContext
  >(
    ['teamRemoveUser'],
    useFetchData<TeamRemoveUserMutation, TeamRemoveUserMutationVariables>(
      TeamRemoveUserDocument
    ),
    options
  )
}

export const DashboardWorkloadRunsDocument = `
    query dashboardWorkloadRuns($createdByUsername: String, $first: Int, $offset: Int, $orderBy: String, $lifecycleStatus: [WorkloadRunLifecycleStatus!]) {
  workloadRuns(
    createdBy_Username: $createdByUsername
    first: $first
    offset: $offset
    orderBy: $orderBy
    lifecycleStatus_In: $lifecycleStatus
  ) {
    totalCount
    edges {
      node {
        team {
          name
          slug
        }
        containerRuntimeRequirements {
          label
        }
        workloadDefinitionSlug
        lifecycleStatus
        namespace
        ingressRouteMatch
      }
    }
  }
}
    `

export const useDashboardWorkloadRunsQuery = <
  TData = DashboardWorkloadRunsQuery,
  TError = unknown,
>(
  variables?: DashboardWorkloadRunsQueryVariables,
  options?: UseQueryOptions<DashboardWorkloadRunsQuery, TError, TData>
) => {
  return useQuery<DashboardWorkloadRunsQuery, TError, TData>(
    variables === undefined
      ? ['dashboardWorkloadRuns']
      : ['dashboardWorkloadRuns', variables],
    useFetchData<
      DashboardWorkloadRunsQuery,
      DashboardWorkloadRunsQueryVariables
    >(DashboardWorkloadRunsDocument).bind(null, variables),
    options
  )
}

export const DashboardVirtualMachinesDocument = `
    query dashboardVirtualMachines($createdByUsername: String, $first: Int, $offset: Int, $orderBy: String, $status: [OpenStackServerStatus!]) {
  servers(
    createdBy_Username: $createdByUsername
    first: $first
    offset: $offset
    orderBy: $orderBy
    status_In: $status
  ) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      node {
        name
        status
        teamName
        teamSlug
        openstackImage {
          name
          defaultSshUser
        }
        openstackFlavor {
          name
        }
        openstackFloatingIp
      }
    }
  }
}
    `

export const useDashboardVirtualMachinesQuery = <
  TData = DashboardVirtualMachinesQuery,
  TError = unknown,
>(
  variables?: DashboardVirtualMachinesQueryVariables,
  options?: UseQueryOptions<DashboardVirtualMachinesQuery, TError, TData>
) => {
  return useQuery<DashboardVirtualMachinesQuery, TError, TData>(
    variables === undefined
      ? ['dashboardVirtualMachines']
      : ['dashboardVirtualMachines', variables],
    useFetchData<
      DashboardVirtualMachinesQuery,
      DashboardVirtualMachinesQueryVariables
    >(DashboardVirtualMachinesDocument).bind(null, variables),
    options
  )
}

export const UsersDocument = `
    query users($search: String, $isActive: Boolean) {
  users(search: $search, isActive: $isActive) {
    edges {
      node {
        ...BasicUserFragment
      }
    }
  }
}
    ${BasicUserFragmentFragmentDoc}`

export const useUsersQuery = <TData = UsersQuery, TError = unknown>(
  variables?: UsersQueryVariables,
  options?: UseQueryOptions<UsersQuery, TError, TData>
) => {
  return useQuery<UsersQuery, TError, TData>(
    variables === undefined ? ['users'] : ['users', variables],
    useFetchData<UsersQuery, UsersQueryVariables>(UsersDocument).bind(
      null,
      variables
    ),
    options
  )
}

export const WhoamiDocument = `
    query whoami {
  whoami {
    id
    email
    username
    firstName
    lastName
    isSuperuser
    isActive
    teams {
      id
      slug
      role
    }
  }
}
    `

export const useWhoamiQuery = <TData = WhoamiQuery, TError = unknown>(
  variables?: WhoamiQueryVariables,
  options?: UseQueryOptions<WhoamiQuery, TError, TData>
) => {
  return useQuery<WhoamiQuery, TError, TData>(
    variables === undefined ? ['whoami'] : ['whoami', variables],
    useFetchData<WhoamiQuery, WhoamiQueryVariables>(WhoamiDocument).bind(
      null,
      variables
    ),
    options
  )
}

export const UserUpdateDocument = `
    mutation userUpdate($input: UserUpdateInput!) {
  userUpdate(input: $input) {
    user {
      ...BasicUserFragment
    }
  }
}
    ${BasicUserFragmentFragmentDoc}`

export const useUserUpdateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UserUpdateMutation,
    TError,
    UserUpdateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UserUpdateMutation,
    TError,
    UserUpdateMutationVariables,
    TContext
  >(
    ['userUpdate'],
    useFetchData<UserUpdateMutation, UserUpdateMutationVariables>(
      UserUpdateDocument
    ),
    options
  )
}

export const WorkloadDefinitionsDocument = `
    query workloadDefinitions($teamId: ID, $first: Int, $offset: Int, $orderBy: String, $isArchived: Boolean, $slug: String) {
  workloadDefinitions(
    teamId: $teamId
    first: $first
    offset: $offset
    orderBy: $orderBy
    isArchived: $isArchived
    slug: $slug
  ) {
    edges {
      node {
        ...WorkloadDefinitionFragment
      }
    }
  }
}
    ${WorkloadDefinitionFragmentFragmentDoc}`

export const useWorkloadDefinitionsQuery = <
  TData = WorkloadDefinitionsQuery,
  TError = unknown,
>(
  variables?: WorkloadDefinitionsQueryVariables,
  options?: UseQueryOptions<WorkloadDefinitionsQuery, TError, TData>
) => {
  return useQuery<WorkloadDefinitionsQuery, TError, TData>(
    variables === undefined
      ? ['workloadDefinitions']
      : ['workloadDefinitions', variables],
    useFetchData<WorkloadDefinitionsQuery, WorkloadDefinitionsQueryVariables>(
      WorkloadDefinitionsDocument
    ).bind(null, variables),
    options
  )
}

export const WorkloadDefinitionNavigationDocument = `
    query workloadDefinitionNavigation($teamId: ID, $first: Int, $offset: Int, $orderBy: String, $isArchived: Boolean) {
  workloadDefinitions(
    teamId: $teamId
    first: $first
    offset: $offset
    orderBy: $orderBy
    isArchived: $isArchived
  ) {
    edges {
      node {
        id
        name
        slug
        runsActiveCount
      }
    }
  }
}
    `

export const useWorkloadDefinitionNavigationQuery = <
  TData = WorkloadDefinitionNavigationQuery,
  TError = unknown,
>(
  variables?: WorkloadDefinitionNavigationQueryVariables,
  options?: UseQueryOptions<WorkloadDefinitionNavigationQuery, TError, TData>
) => {
  return useQuery<WorkloadDefinitionNavigationQuery, TError, TData>(
    variables === undefined
      ? ['workloadDefinitionNavigation']
      : ['workloadDefinitionNavigation', variables],
    useFetchData<
      WorkloadDefinitionNavigationQuery,
      WorkloadDefinitionNavigationQueryVariables
    >(WorkloadDefinitionNavigationDocument).bind(null, variables),
    options
  )
}

export const WorkloadDefinitionCreateDocument = `
    mutation workloadDefinitionCreate($input: WorkloadDefinitionCreateInput!) {
  workloadDefinitionCreate(input: $input) {
    workloadDefinition {
      ...WorkloadDefinitionFragment
    }
  }
}
    ${WorkloadDefinitionFragmentFragmentDoc}`

export const useWorkloadDefinitionCreateMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    WorkloadDefinitionCreateMutation,
    TError,
    WorkloadDefinitionCreateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    WorkloadDefinitionCreateMutation,
    TError,
    WorkloadDefinitionCreateMutationVariables,
    TContext
  >(
    ['workloadDefinitionCreate'],
    useFetchData<
      WorkloadDefinitionCreateMutation,
      WorkloadDefinitionCreateMutationVariables
    >(WorkloadDefinitionCreateDocument),
    options
  )
}

export const WorkloadDefinitionUpdateDocument = `
    mutation workloadDefinitionUpdate($input: WorkloadDefinitionUpdateInput!) {
  workloadDefinitionUpdate(input: $input) {
    workloadDefinition {
      ...WorkloadDefinitionFragment
    }
  }
}
    ${WorkloadDefinitionFragmentFragmentDoc}`

export const useWorkloadDefinitionUpdateMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    WorkloadDefinitionUpdateMutation,
    TError,
    WorkloadDefinitionUpdateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    WorkloadDefinitionUpdateMutation,
    TError,
    WorkloadDefinitionUpdateMutationVariables,
    TContext
  >(
    ['workloadDefinitionUpdate'],
    useFetchData<
      WorkloadDefinitionUpdateMutation,
      WorkloadDefinitionUpdateMutationVariables
    >(WorkloadDefinitionUpdateDocument),
    options
  )
}

export const WorkloadDefinitionDeleteDocument = `
    mutation workloadDefinitionDelete($input: WorkloadDefinitionDeleteInput!) {
  workloadDefinitionDelete(input: $input) {
    ok
  }
}
    `

export const useWorkloadDefinitionDeleteMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    WorkloadDefinitionDeleteMutation,
    TError,
    WorkloadDefinitionDeleteMutationVariables,
    TContext
  >
) => {
  return useMutation<
    WorkloadDefinitionDeleteMutation,
    TError,
    WorkloadDefinitionDeleteMutationVariables,
    TContext
  >(
    ['workloadDefinitionDelete'],
    useFetchData<
      WorkloadDefinitionDeleteMutation,
      WorkloadDefinitionDeleteMutationVariables
    >(WorkloadDefinitionDeleteDocument),
    options
  )
}

export const WorkloadDefinitionUnarchiveDocument = `
    mutation workloadDefinitionUnarchive($input: WorkloadDefinitionUnarchiveInput!) {
  workloadDefinitionUnarchive(input: $input) {
    ok
  }
}
    `

export const useWorkloadDefinitionUnarchiveMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    WorkloadDefinitionUnarchiveMutation,
    TError,
    WorkloadDefinitionUnarchiveMutationVariables,
    TContext
  >
) => {
  return useMutation<
    WorkloadDefinitionUnarchiveMutation,
    TError,
    WorkloadDefinitionUnarchiveMutationVariables,
    TContext
  >(
    ['workloadDefinitionUnarchive'],
    useFetchData<
      WorkloadDefinitionUnarchiveMutation,
      WorkloadDefinitionUnarchiveMutationVariables
    >(WorkloadDefinitionUnarchiveDocument),
    options
  )
}

export const WorkloadRunsDocument = `
    query workloadRuns($teamId: ID, $first: Int, $offset: Int, $orderBy: String, $workloadDefinitionId: ID, $lifecycleStatus: [WorkloadRunLifecycleStatus!], $firstName: String, $lastName: String, $username: String, $teamName: String, $runtimeRequirement: String) {
  workloadRuns(
    teamId: $teamId
    first: $first
    offset: $offset
    orderBy: $orderBy
    workloadDefinitionId: $workloadDefinitionId
    lifecycleStatus_In: $lifecycleStatus
    createdBy_FirstName_Icontains: $firstName
    createdBy_LastName_Icontains: $lastName
    createdBy_Username_Icontains: $username
    team_Name: $teamName
    containerRuntimeRequirements_Label: $runtimeRequirement
  ) {
    totalCount
    edges {
      node {
        ...WorkloadRunFragment
      }
    }
  }
}
    ${WorkloadRunFragmentFragmentDoc}`

export const useWorkloadRunsQuery = <
  TData = WorkloadRunsQuery,
  TError = unknown,
>(
  variables?: WorkloadRunsQueryVariables,
  options?: UseQueryOptions<WorkloadRunsQuery, TError, TData>
) => {
  return useQuery<WorkloadRunsQuery, TError, TData>(
    variables === undefined ? ['workloadRuns'] : ['workloadRuns', variables],
    useFetchData<WorkloadRunsQuery, WorkloadRunsQueryVariables>(
      WorkloadRunsDocument
    ).bind(null, variables),
    options
  )
}

export const WorkloadRuntimeCapacityDocument = `
    query WorkloadRuntimeCapacity($containerRuntimeId: ID!, $teamId: ID) {
  workloadRuntimeCapacity(
    containerRuntimeId: $containerRuntimeId
    teamId: $teamId
  ) {
    maximumScale
    singleInstanceCapacity
  }
}
    `

export const useWorkloadRuntimeCapacityQuery = <
  TData = WorkloadRuntimeCapacityQuery,
  TError = unknown,
>(
  variables: WorkloadRuntimeCapacityQueryVariables,
  options?: UseQueryOptions<WorkloadRuntimeCapacityQuery, TError, TData>
) => {
  return useQuery<WorkloadRuntimeCapacityQuery, TError, TData>(
    ['WorkloadRuntimeCapacity', variables],
    useFetchData<
      WorkloadRuntimeCapacityQuery,
      WorkloadRuntimeCapacityQueryVariables
    >(WorkloadRuntimeCapacityDocument).bind(null, variables),
    options
  )
}

export const WorkloadRunCreateDocument = `
    mutation workloadRunCreate($input: WorkloadRunCreateInput!) {
  workloadRunCreate(input: $input) {
    workloadRun {
      ...WorkloadRunFragment
    }
  }
}
    ${WorkloadRunFragmentFragmentDoc}`

export const useWorkloadRunCreateMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    WorkloadRunCreateMutation,
    TError,
    WorkloadRunCreateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    WorkloadRunCreateMutation,
    TError,
    WorkloadRunCreateMutationVariables,
    TContext
  >(
    ['workloadRunCreate'],
    useFetchData<WorkloadRunCreateMutation, WorkloadRunCreateMutationVariables>(
      WorkloadRunCreateDocument
    ),
    options
  )
}

export const WorkloadRunCommentUpdateDocument = `
    mutation WorkloadRunCommentUpdate($input: WorkloadRunCommentUpdateInput!) {
  workloadRunCommentUpdate(input: $input) {
    workloadRun {
      ...WorkloadRunFragment
    }
  }
}
    ${WorkloadRunFragmentFragmentDoc}`

export const useWorkloadRunCommentUpdateMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    WorkloadRunCommentUpdateMutation,
    TError,
    WorkloadRunCommentUpdateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    WorkloadRunCommentUpdateMutation,
    TError,
    WorkloadRunCommentUpdateMutationVariables,
    TContext
  >(
    ['WorkloadRunCommentUpdate'],
    useFetchData<
      WorkloadRunCommentUpdateMutation,
      WorkloadRunCommentUpdateMutationVariables
    >(WorkloadRunCommentUpdateDocument),
    options
  )
}

export const WorkloadRunRestartDocument = `
    mutation workloadRunRestart($input: WorkloadRunRestartInput!) {
  workloadRunRestart(input: $input) {
    workloadRun {
      ...WorkloadRunFragment
    }
  }
}
    ${WorkloadRunFragmentFragmentDoc}`

export const useWorkloadRunRestartMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    WorkloadRunRestartMutation,
    TError,
    WorkloadRunRestartMutationVariables,
    TContext
  >
) => {
  return useMutation<
    WorkloadRunRestartMutation,
    TError,
    WorkloadRunRestartMutationVariables,
    TContext
  >(
    ['workloadRunRestart'],
    useFetchData<
      WorkloadRunRestartMutation,
      WorkloadRunRestartMutationVariables
    >(WorkloadRunRestartDocument),
    options
  )
}

export const WorkloadRunDeleteDocument = `
    mutation workloadRunDelete($input: WorkloadRunDeleteInput!) {
  workloadRunDelete(input: $input) {
    workloadRun {
      ...WorkloadRunFragment
    }
  }
}
    ${WorkloadRunFragmentFragmentDoc}`

export const useWorkloadRunDeleteMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    WorkloadRunDeleteMutation,
    TError,
    WorkloadRunDeleteMutationVariables,
    TContext
  >
) => {
  return useMutation<
    WorkloadRunDeleteMutation,
    TError,
    WorkloadRunDeleteMutationVariables,
    TContext
  >(
    ['workloadRunDelete'],
    useFetchData<WorkloadRunDeleteMutation, WorkloadRunDeleteMutationVariables>(
      WorkloadRunDeleteDocument
    ),
    options
  )
}

export const KubeconfigDocument = `
    query kubeconfig {
  kubeconfig {
    kubeconfig
  }
}
    `

export const useKubeconfigQuery = <TData = KubeconfigQuery, TError = unknown>(
  variables?: KubeconfigQueryVariables,
  options?: UseQueryOptions<KubeconfigQuery, TError, TData>
) => {
  return useQuery<KubeconfigQuery, TError, TData>(
    variables === undefined ? ['kubeconfig'] : ['kubeconfig', variables],
    useFetchData<KubeconfigQuery, KubeconfigQueryVariables>(
      KubeconfigDocument
    ).bind(null, variables),
    options
  )
}
