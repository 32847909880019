import styled from 'styled-components'
import * as Icons from '@heroicons/react/24/outline'
import * as SolidIcons from '@heroicons/react/24/solid'
import { HTMLAttributes } from 'react'

export const ExternalLinkIcon = styled(Icons.ArrowTopRightOnSquareIcon)`
  height: 1.25rem;
  vertical-align: sub;
`

export const ExclamationCircleIcon = styled(Icons.ExclamationCircleIcon)`
  height: 1.25rem;
  color: red;
`

export const ExclamationTriangleIcon = styled(Icons.ExclamationTriangleIcon)`
  height: 1.25rem;
  color: orange;
`

export const ServerStackIcon = styled(Icons.ServerStackIcon)`
  height: 1.25rem;
`

export const DotsHorizontalIcon = styled(Icons.EllipsisHorizontalIcon)`
  height: 1.25rem;
`

export const CheckCircleIcon = styled(Icons.CheckCircleIcon)`
  height: 1.25rem;
  color: green;
`

export const CheckCircleInactiveIcon = styled(Icons.CheckCircleIcon)`
  height: 1.25rem;
  color: gray;
`

export const PlayIcon = styled(Icons.PlayIcon)`
  height: 1.25rem;
  color: green;
`

export const StopIcon = styled(Icons.StopIcon)`
  height: 1.25rem;
  color: green;
`

export const CheckIcon = styled(Icons.CheckIcon)`
  height: 1.25rem;
`

export const LoginIcon = styled(Icons.ArrowLeftEndOnRectangleIcon)`
  height: 1.25rem;
`

const RotatingIcon = styled.span`
  line-height: 20px;
  width: 20px;
  height: 20px;
  display: inline-block;
  animation: rotation 1.5s infinite linear;
  color: purple;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`

export const PendingIcon = (props: HTMLAttributes<HTMLSpanElement>) => (
  <RotatingIcon {...props}>
    <Icons.ArrowPathIcon />
  </RotatingIcon>
)

export const PendingActiveIcon = styled(PendingIcon)`
  color: green;
`

export const PendingInactiveIcon = styled(PendingIcon)`
  color: gray;
`

export const CopyIcon = styled(Icons.ClipboardDocumentIcon)`
  height: 1rem;
`

export const ChevronLeftIcon = styled(Icons.ChevronLeftIcon)`
  height: 1rem;
`

export const ChevronRightIcon = styled(Icons.ChevronRightIcon)`
  height: 1rem;
`

export const ChevronDownIcon = styled(Icons.ChevronDownIcon)`
  height: 1.25rem;
  margin-right: 12px;
`

export const SolidSelectorIcon = styled(SolidIcons.ChevronUpDownIcon)`
  height: 1rem;
`

export const BiggerSelectorIcon = styled(Icons.ChevronUpDownIcon)`
  height: 1.25rem;
`

export const SearchIcon = styled(Icons.MagnifyingGlassIcon)`
  height: 1.25rem;
`

export const XIcon = styled(Icons.XMarkIcon)`
  height: 1.25rem;
`

export const SolidSortAscendingIcon = styled(SolidIcons.BarsArrowUpIcon)`
  height: 1rem;
`
export const SolidSortDescendingIcon = styled(SolidIcons.BarsArrowDownIcon)`
  height: 1rem;
`

export const UserCircleIcon = styled(Icons.UserCircleIcon)`
  height: 1.25rem;
`

export const PlusCircleIcon = styled(Icons.PlusCircleIcon)`
  height: 1.25rem;
  color: var(--tt-green);
`

export const HintIcon = styled(Icons.ExclamationCircleIcon)`
  display: inline-block;
  height: 1rem;
  vertical-align: sub;
`

export const DownloadIcon = styled(Icons.ArrowDownTrayIcon)`
  height: 1rem;
  margin-right: 12px;
`

export const AddFilterIcon = styled(Icons.MagnifyingGlassPlusIcon)`
  height: 1.25rem;
`

export const EyeIcon = styled(Icons.EyeIcon)`
  height: 1.25rem;
`

export const EyeSlashIcon = styled(Icons.EyeSlashIcon)`
  height: 1.25rem;
`

export const TeamAdminIcon = styled(Icons.UserGroupIcon)`
  height: 1.25rem;
`

export const SuperuserIcon = styled(Icons.WrenchScrewdriverIcon)`
  height: 1.25rem;
`

export const IngressRouteIcon = styled(Icons.LinkIcon)`
  height: 1.25rem;
  vertical-align: sub;
`

export const DeleteIcon = styled(Icons.TrashIcon)`
  height: 1.25rem;
  vertical-align: sub;
`

export const CommentIcon = styled(Icons.ChatBubbleOvalLeftEllipsisIcon)`
  height: 1.25rem;
`

export const RestartIcon = styled(Icons.ArrowPathIcon)`
  height: 1.25rem;
  vertical-align: sub;
`
